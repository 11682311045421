.radioField() {

    .radio-field-wrapper {
        user-select: none;
        display: flex;

        .radio-field-label {
            margin-top: auto;
            margin-bottom: auto;
        }

        .option-label { 
            margin-top: auto;
            margin-bottom: auto;

            &.active span {
                font-weight: bolder;
            }
        }

        .option-label span {
            font-weight: 200;
            margin-left: .25rem;
        }

        input {
            margin-left: 1rem;
        }

        &.disabled {

            .radio-field-label {
                color: .themes[@@gray6];
            }
            
            .option-label {
                color: .themes[@@gray6];
            }
        }
    }
}