.chips() {

    .chip {
        display: flex;
        position: relative;
        font-size: .875rem;
        color: .themes[@@gray7];
        border-radius: 50rem;
        padding: .375rem .875rem;
        width: fit-content;
        height: 2.25rem;
        font-weight: 400;
        letter-spacing: .25px;
        line-height: 1.25rem;
        background-color: .themes[@@gray2];
        box-sizing: border-box;
        user-select: none;

        &.outline {
            background-color: transparent;
            border: 1px solid .themes[@@gray3];
        }

        &.clickable {
            cursor: pointer;
        }

        &.elevate {
            background-color: transparent;
            .elevate-1();
        }

        &.elevate.clickable {
            .transitions(box-shadow);

            &:hover {
                .elevate-2();
            }

            &:active {
                .elevate-0();
            }
        }

        &.primary {
            color: .themes[@@primary];
        }
        &.primary.outline {
            border-color: .themes[@@primary];
        }

        &.danger {
            color: .themes[@@danger];
        }
        &.danger.outline {
            border-color: .themes[@@danger];
        }

        &.accent {
            color: .themes[@@accent];
        }
        &.accent.outline {
            border-color: .themes[@@accent];
        }

        &.success {
            color: .themes[@@success];
        }
        &.success.outline {
            border-color: .themes[@@success];
        }

        &.chip-small {
            font-size: .7rem;
            padding: .125rem .5rem;
            line-height: .75rem;
            height: 1.5rem;
            letter-spacing: 1px;
            font-weight: 500;
            
            .icon-wrapper {
                font-size: 0.65rem;
                margin-top: .35rem;
            }

            .icon-wrapper:first-of-type {
                margin-left: 0.25rem;
                margin-right: .125rem;
            }

            .icon-wrapper:last-of-type {
                margin-left: .125rem;
                margin-right: 0.25rem;
            }

            span { 
                margin-top: auto;
                margin-bottom: auto;
                margin-left: .25rem;
                margin-right: .25rem;
            }
        }

        &.chip-small.outline {
            .icon-wrapper {
                margin-top: .3rem;
            }
        }

        .icon-wrapper {
            margin-top: auto;
            margin-bottom: auto;
            font-size: .75rem;
        }
        
        span { 
            padding-top: 2px;   
            margin-top: auto;
            margin-bottom: auto;
            margin-left: .5rem;
            margin-right: .5rem;
        }

        .transitions(@prop) {
            -webkit-transition: @prop 0.25s ease-out;
            -moz-transition: @prop 0.25s ease-out;
            -o-transition: @prop 0.25s ease-out;
            transition: @prop 0.25s ease-out;
        }
    }
}