.tabs() {
    @tabButtonWidth: 8rem;

    .tab-group {

        .tab-heading {
            border-bottom: 1px solid .themes[@@gray3];
            user-select: none;

            .tab-button-wrapper {
                display: flex;
                min-height: 2rem;
                
                .tab-button {
                    width: 8rem;
                }
            }

            .tab-button-indicator-wrapper {
                position: relative;

                .tab-button-indicator {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    height: .125rem;
                    width: @tabButtonWidth;
                    background-color: .themes[@@primary];

                    -webkit-transition: left 0.25s ease-out;
                    -moz-transition: left 0.25s ease-out;
                    -o-transition: left 0.25s ease-out;
                    transition: left 0.25s ease-out;

                    &.active-0 {
                        left: 0
                    }
                    &.active-1 {
                        left: @tabButtonWidth
                    }
                    &.active-2 {
                        left: @tabButtonWidth * 2;
                    }
                    &.active-3 {
                        left: @tabButtonWidth * 3;
                    }
                    &.active-4 {
                        left: @tabButtonWidth * 4;
                    }
                    &.active-5 {
                        left: @tabButtonWidth * 5;
                    }
                    &.active-6 {
                        left: @tabButtonWidth * 6;
                    }
                    &.active-7 {
                        left: @tabButtonWidth * 7;
                    }
                    &.active-8 {
                        left: @tabButtonWidth * 8;
                    }
                    &.active-9 {
                        left: @tabButtonWidth * 9;
                    }
                    &.active-10 {
                        left: @tabButtonWidth * 10;
                    }
                    &.active-11 {
                        left: @tabButtonWidth * 11;
                    }
                }
            }
        }

        .tab-content {
            box-sizing: border-box;
            
            .tab {
                width: 100%;
                box-sizing: border-box;
                opacity: 0;
                display: none;                
                
                &.active {
                    display: block;
                    opacity: 1;

                    .fadeIn(0.25s);
                }
            }
        }
    }
}