.mixins() {

    .elevate-0() {
        box-shadow: none;
    }
        
    .elevate-1(@color1: rgba(8, 35, 48, 0.16), @color2: rgba(8, 35, 48, 0.24)) {
        box-shadow: 0px 2px 6px @color1, 0px 1px 2px @color2;
    }

    .elevate-2(@color1: rgba(8, 35, 48, 0.16), @color2: rgba(8, 35, 48, 0.24)) {
        box-shadow: 0px 4px 8px @color1, 0px 2px 4px @color2;
    }

    .elevate-3(@color1: rgba(8, 35, 48, 0.1), @color2: rgba(8, 35, 48, 0.16)) {
        box-shadow: 0px 8px 16px @color1, 0px 4px 8px @color2;
    }

    .elevate-4(@color1: rgba(8, 35, 48, 0.08), @color2: rgba(8, 35, 48, 0.14)) {
        box-shadow: 0px 16px 24px @color1, 0px 6px 12px @color2;
    }

    .elevate-5(@color1: rgba(8, 35, 48, 0.06), @color2: rgba(8, 35, 48, 0.1)) {
        box-shadow: 0px 24px 32px @color1, 0px 8px 16px @color2;
    }

    .primary-bg() {
        background-color: .themes[@@primary];
        color: .themes[@@gray0];
    }
    .danger-bg() {
        background-color: .themes[@@danger];
        color: .themes[@@gray0];
    }
    .warn-bg() {
        background-color: .themes[@@warn];
        color: .themes[@@gray0];
    }
    .success-bg() {
        background-color: .themes[@@success];
        color: .themes[@@gray0];
    }
    
    .flatten() { 
        border-color: transparent;
        box-shadow: none;
    }
    .primary-bg() {
        background-color: .themes[@@primary];
        color: .themes[@@gray0];
    }
    .danger-bg() {
        background-color: .themes[@@danger];
        color: .themes[@@gray0];
    }
    .warn-bg() {
        background-color: .themes[@@warn];
        color: .themes[@@gray0];
    }
    .success-bg() {
        background-color: .themes[@@success];
        color: .themes[@@gray0];
    }

    .accent-bg() {
        background-color: .themes[@@accent];
        color: .themes[@@gray0];
    }

    .gray1-bg {
        background-color: .themes[@@gray1];
    }

    .round {
        border-radius: .5rem;        
    }

    .x-round {
        border-radius: 1rem;
    }

    .xx-round {
        border-radius: 1.5rem;
    }

    .xxx-round {
        border-radius: 2rem;
    }

    .elevate-0 {
        .elevate-0();
    }
    .elevate-1 {
        .elevate-1();
    }
    .elevate-2 {
        .elevate-2();
    }
    .elevate-3 {
        .elevate-3();
    }
    .elevate-4 {
        .elevate-4();
    }
    .elevate-5 {
        .elevate-5();
    }

    .no-wrap() {
        white-space: nowrap;
    }

    .no-wrap {
        .no-wrap();
    }
}