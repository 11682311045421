.textfields() {

    .text-field-wrapper {
        position: relative;
        width: 100%;
        height: 3.5rem;
        box-sizing: border-box;

        label {
            background-color: #FFF;
            position: absolute;
            top: 0rem;
            left: 0.5rem;
            padding: 0 0.15rem;
            font-size: 0.75rem;
            line-height: 1rem;
            z-index: 1000;
            color: .themes[@@gray7];    
            font-weight: 400;
            user-select: none;
        }

        &.invalid label {
            color: red;
        }

        input {
            position: absolute;
            left: 0;
            top: 0.5rem;
            width: 100%;
            height: 2rem;
            background-color: transparent;
            padding: 0 0.5rem;
            border: 1px solid .themes[@@gray3];
            color: .themes[@@gray7];
            box-sizing: border-box;
            font-weight: 600;
            padding-top: .5rem;

            &:hover, &:active, &:focus {
                outline: none;
            }

            &::placeholder {
                font-weight: 100;
                font-size: 80%;
                color: .themes[@@gray4];
            }
        }

        &.invalid input {
            color: red;
            border-color: red;

            &::placeholder { 
                color: red;
            }
        }

        .error-message { 
            color: red;
            font-size: 0.75rem;
            position: absolute;
            left: 0rem;
            bottom: 0;
            line-height: 1rem;
            width: 100%;
            text-align: right;
            font-weight: 400;
        }

        &.inline-label {
            display: flex;
            height: 2rem;

            label {
                position: initial;
                margin-top: auto;
                margin-bottom: auto;
                margin-right: 1rem;
                white-space: nowrap;
            }

            input {
                position: initial;
                margin-top: auto;
                margin-bottom: auto;
                top: unset;
                padding-top: 0;
            }

            .error-message {
                box-sizing: border-box;
                bottom: .45rem;
                padding-right: .5rem;
            }
        }

        &.inline-validation.inline-label { 
            height: auto;
        }

        &.text-small input {
            .text-small();
        }

        &.on-blackout input {
            color: .themes[@@gray0];
        }

        &.disabled {

            input {
                background-color: .themes[@@gray2];
                color: .themes[@@gray6];
            }

            label {
                background-color: transparent;
                color: .themes[@@gray6];
            }
        }
    }
}