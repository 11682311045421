.toasts() {

    @toast-success: .themes[@@success];
    @toast-danger: .themes[@@danger];
    @toast-accent: .themes[@@accent];
    @toast-warn: .themes[@@warn];
    @toast-primary: .themes[@@primary];

    .toast-container {
        position: fixed;
        top: 2rem;
        right: 2rem;
        width: 100%;
        max-width: 25rem;
        z-index: 5000;

        .toast-wrapper + .toast-wrapper {
            margin-top: .5rem
        }

        .toast-wrapper {
            display: flex;
            padding: 0 0 0 .5rem;
            color: .themes[@@gray8];
            border-radius: .5rem;

            &.success {
                border: 1px solid @toast-success;
                background-color: rgba(red(@toast-success), green(@toast-success), blue(@toast-success), .8);
            }
            
            &.danger {
                border: 1px solid @toast-danger;
                background-color: rgba(red(@toast-danger), green(@toast-danger), blue(@toast-danger), .8);
            }
            
            &.accent {
                border: 1px solid @toast-accent;
                background-color: rgba(red(@toast-accent), green(@toast-accent), blue(@toast-accent), .8);
            }
            
            &.warn {
                border: 1px solid @toast-warn;
                background-color: rgba(red(@toast-warn), green(@toast-warn), blue(@toast-warn), .8);
            }
            
            &.primary {
                border: 1px solid @toast-primary;
                background-color: rgba(red(@toast-primary), green(@toast-primary), blue(@toast-primary), .8);
            }
        }

        .toast-message {
            line-height: 1rem;
        }

        .toast-body {
            background-color: .themes[@@gray0];
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
            padding: .5rem 1rem;
        }

        .close-button {
            height: auto;
            width: auto;
            min-width: unset;
            color: .themes[@@gray4];
            background-color: transparent;
            padding: 0;

            &:hover {
                color: .themes[@@gray8];
            }
        }

        .toast-icon {
            color: .themes[@@gray1];
        }
    }
}