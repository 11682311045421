.fade-in {
    .fadeIn(0.25s)
}

.fade-out {
    .fadeOut(0.25s)
}

.fadeIn(@time) {
    -webkit-animation: fadeIn @time;
    animation: fadeIn @time;
}

@-webkit-keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.fadeOut(@time) {
    -webkit-animation: fadeOut @time;
    animation: fadeOut @time;
}

@-webkit-keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
}

@keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
}