.tables() {

    table {     
        font-size: .875rem;
        border-collapse: collapse;
        width: 100%;

        thead tr th {
            color: .themes[@@tableHeaderColor];
            font-weight: normal;
            border-bottom: 2px solid .themes[@@tableBorderColor];
            text-align: left;
            padding: 0 .5rem;
            border-top: 2px solid transparent;
            box-sizing: border-box;
            user-select: none;

            &.draggable {
                padding-left: 0;

                .table-header-drag-handle {
                    visibility: hidden;
                    cursor: move;
                    margin-right: .25rem;
                }

                &:hover {
                    .table-header-drag-handle {
                        visibility: visible;
                    }
                }
            }
        }

        thead tr th .table-sort-button {
            background-color: transparent;
            text-transform: none;
            padding: 0;
            display: inline;
            min-width: 0;
            height: auto;
            color: .themes[@@primary];

            span {
                margin: 0;
            }

            .icon-wrapper {
                display: inline-block;
                font-size: .75rem;
                margin-left: 0.5rem;
            }
        }

        tbody { 
            border-bottom: 1px solid .themes[@@tableBorderColor];
        }

        tbody tr td {
            color: .themes[@@tableBodyColor];
            font-weight: normal;
            vertical-align: middle;  
            padding: .15rem .5rem; 
        }

        input[type=checkbox] {
            border-color: .themes[@@tableBorderColor];
            outline: .themes[@@tableBorderColor];
        }

        tbody:hover {
            background-color: .themes[@@tableRowHoverColor];
        }

        .button-column {
            .fit-content();
        }

        .selecter-column {
            padding-left: 1rem;
            padding-right: 1rem;
            .fit-content();
        }

        .expander-column {
            padding-left: 1rem;
            padding-right: 1rem;
            cursor: pointer;
            .fit-content();
        }

        .expander-column + .selecter-column {
            padding-left: .5rem;
        }

        .expander-column svg {
            transform: rotate(0deg);
            -webkit-transition: transform 0.25s linear;
            -moz-transition: transform 0.25s linear;
            -o-transition: transform 0.25s linear;
            transition: transform 0.25s linear;
        }

        .expander-column.active svg {
            transform: rotate(90deg);
        }

        .fit-content {
            .fit-content();
        }

        .expanded-content-row td {
            line-height: 0px;
            -webkit-transition: line-height 0.25s linear;
            -moz-transition: line-height 0.25s linear;
            -o-transition: line-height 0.25s linear;
            transition: line-height 0.25s linear;
            padding: 0;
            overflow: hidden;   
        }

        .expanded-content-row.active td {
            line-height: 1.5rem;
        }
    }

    .column-drop-placeholder {
        margin-top: 2px;
        box-sizing: border-box;
        background-color: .themes[@@drop-background-color];
        border: 2px dashed .themes[@@drop-border-color];
    }

    .draggable-column-table-container {
        display: flex;
    }

    .draggable-column-table-container .draggable-column-table-wrapper {
        user-select: none;
        background-color: white;
    }

    .draggable-column-table-container .draggable-column-table-wrapper th {
        white-space: nowrap;
    }

    .pagination-row {

        .navigate-button {
            border: 1px solid .themes[@@navigateButtonColor];
            background: transparent;
            color: .themes[@@navigateButtonColor];
        }

        .navigate-button:hover {
            @color: .themes[@@navigateButtonColor];
            border: 1px solid darken(@color, 40%);
            color: darken(@color, 40%);
        }
        
        .go-to-page-button {
            background: .themes[@@goToPageButtonBackgroundColor];
            color: .themes[@@goToPageButtonColor];
            font-weight: bold;
        }

        .go-to-page-button:hover {
            @bgColor: .themes[@@goToPageButtonBackgroundColor];
            @color: .themes[@@goToPageButtonColor];
            background: darken(@bgColor, 5%);
        }

        .go-to-page-button.active {
            background: .themes[@@goToPageButtonBackgroundColorActive];
            color: .themes[@@goToPageButtonColorActive];
            cursor: initial;
        }
    }

    .fit-content() {
        width: 1%;
        white-space: nowrap;
    }

    .error-detail {
        border: 1px solid .themes[@@gray3];
        padding: .5rem;
    }
}