.queryBuilder() {

    .query-builder-condition-set {
        width: fit-content;
        box-sizing: border-box;
        position: relative;

        .query-builder-condition-set-pointer {
            border-top: 1px dashed .themes[@@gray4];
            position: absolute;
            top: 1rem;
            left: -1rem;
            width: 1rem;
        }
        
        .query-builder-condition-container {
            border-left: 1px dashed .themes[@@gray4];
            margin-left: 1rem;
            box-sizing: border-box;
            padding-top: 1rem;
            padding-bottom: 1rem;
            padding-right: 1rem;

            .query-builder-condition {
                position: relative;
                display: flex;
                height: 3rem;
                margin-top: 1rem;
                margin-left: 1rem;
                box-sizing: border-box;
                .elevate-1();
                width: calc(100% - 1rem);

                & > span {
                    margin-top: auto;
                    margin-bottom: auto;
                }

                &:first-of-type {
                    margin-top: 0;
                }

                .query-builder-condition-pointer {
                    border-top: 1px dashed .themes[@@gray4];
                    position: absolute;
                    top: 1.5rem;
                    left: -1rem;
                    width: 1rem;
                }
            }
        }

        .query-builder-condition-set {
            margin-top: 1rem;
            margin-left: 1rem;
        }

        .add-condition-button {
            margin-left: .25rem;
            margin-top: .25rem;
        }
    }
}