.menu() {

    .menu {
        opacity: 0;
        background-color: .themes[@@gray0];
        z-index: 1001;
        height: 0;
        overflow: hidden;
        .elevate-2();
        user-select: none;

        -webkit-transition: opacity 0.1s ease-out;
        -moz-transition: opacity 0.1s ease-out;
        -o-transition: opacity 0.1s ease-out;
        transition: opacity 0.1s ease-out;

        &.active {
            opacity: 1;
            height: auto;
        }

        button.menu-button {
            padding: .25rem .5rem;
            width: 100%;
        }

        button.menu-button *:last-child {
            margin-right: auto;
        }

        .menu-item {
            width: 100%;
        }

        .menu-item:first-of-type button.menu-button {
            padding-top: .5rem;
        }

        .menu-item:last-of-type button.menu-button {
            padding-bottom: .5rem;
        }  

        .divider {
            border-top: 1px solid .themes[@@gray3];            
            margin: .25rem 0;
        }

        .menu-item-dragging {
            position: absolute;
            border: 1px solid .themes[@@gray3];
            background-color: .themes[@@gray0];
        }

    }

    .menu-item-drop-placeholder {
        width: 100%;
        margin-top: 2px;
        box-sizing: border-box;
        background-color: .themes[@@drop-background-color];
        border: 2px dashed .themes[@@drop-border-color];
    }

}