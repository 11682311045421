.loadingMask() {

    .loading-mask {

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: .themes[@@gray0];
        z-index: 3000;
        opacity: .4;
    }
}