@compactFactor: .5;
@defaultFactor: 1;
@expandedFactor: 2;

@spaceOptionCount: 8;
@spaceIncrement: .5;

.margin( 
    @spaceNumber, 
    @factor,
    @coordinateAbbreviation: ~'', 
    @coordinate: ~''
){
    @value: @spaceNumber * @spaceIncrement * @factor;
 
    .m@{coordinateAbbreviation}-@{spaceNumber} { margin@{coordinate}: ~"@{value}rem !important" }
};

.padding(
    @spaceNumber, 
    @factor,
    @coordinateAbbreviation: ~'', 
    @coordinate: ~''
){
    @value: @spaceNumber * @spaceIncrement * @factor;
 
    .p@{coordinateAbbreviation}-@{spaceNumber} { padding@{coordinate}: ~"@{value}rem !important" }
};

.margins(@factor) {
    each(range(0, @spaceOptionCount, 1), {
        .margin(@value, @factor);
    });

    each(range(0, @spaceOptionCount, 1), {
        .margin(@value, @factor, t, -top);
        .margin(@value, @factor, r, -right);
        .margin(@value, @factor, b, -bottom);
        .margin(@value, @factor, l, -left);
    });
};

.paddings(@factor) {
    each(range(0, @spaceOptionCount, 1), { 
        .padding(@value, @factor);
    });

    each(range(0, @spaceOptionCount, 1), {
        .padding(@value, @factor, t, -top);
        .padding(@value, @factor, r, -right);
        .padding(@value, @factor, b, -bottom);
        .padding(@value, @factor, l, -left);
    });
}
