.typography() {
    & {
        font-family: Roboto, sans-serif;
        font-weight: 500;
        letter-spacing: 0.44px;
        line-height: 24px;
        color: .themes[@@text1];
    }
    h1,h2,h3,h4,h5,h6 {
        font-family: Quicksand, Roboto, sans-serif;
    }
    h1 {
        font-size: 6rem;
        letter-spacing: -1.5px;
        line-height: 120px;
        margin: 0;
    }
    h2 {
        font-size: 3.75rem;
        letter-spacing: 0px;
        line-height: 75px;
        margin: 0;
    }
    h3 {
        font-size: 3rem;
        letter-spacing: 0px;
        line-height: 60px;
        margin: 0;
    }
    h4 {
        font-size: 2.125rem;
        line-height: 42px;
        letter-spacing: 0.25px;
        margin: 0;
    }
    h5 {
        font-size: 1.5rem;
        letter-spacing: 0px;
        line-height: 30px;
        margin: 0;
    }
    h6 {
        font-size: 1.25rem;
        letter-spacing: 0.15px;
        line-height: 28px;
        margin: 0;
    }
    .text-uppercase {
        text-transform: uppercase;
    }
    .text-standard {
        text-transform: none;;
    }
    .text-center {
        text-align: center;
    }
    .text-left {
        text-align: left;
    }
    .text-right {
        text-align: right;
    }
    .text-small {
        font-size: .75rem;
        letter-spacing: .25px;
    }
    .text-large {
        font-size: 1.25rem;
    }
    .text-x-large {
        font-size: 1.5rem;
    }
    .text-xx-large {
        font-size: 2rem;
    }
    .text-xxx-large {
        font-size: 3rem;
    }
    .text-super-large {
        font-size: 4rem;
    }
    .text-jumbo-large {
        font-size: 6rem;
    }
    .text-primary {
        color: .themes[@@primary];
    }
    .text-danger {
        color: .themes[@@danger];
    }
    .text-warn {
        color: .themes[@@warn];
    }
    .text-success {
        color: .themes[@@success];
    }
    .text-accent {
        color: .themes[@@accent];
    }
    .text-faint {
        color: .themes[@@gray6];
    }
    .text-bold {
        font-weight: bold;
    }
    .text-light {
        font-weight: lighter;
    }
    .text-italic {
        font-style: italic;
    }
    b, strong {
        font-weight: bold;
    }
}