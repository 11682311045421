.checkboxes() {

    .checkbox-field-wrapper {
        display: flex;
        font-size: .75rem;
    
        .checkbox-wrapper {
            position: relative;
            height: 1rem;
            width: 1rem;
            margin: auto 0;
            cursor: pointer;

            .checkbox-background {
                top: 0.0625rem;
                border-radius: .1875rem;
                width: .875rem;
                height: .875rem;
                position: absolute;   
            }

            .checkbox {
                position: absolute;
                top: 0;
                left: 0;
                font-size: 1rem;
            }

            .check {
                z-index: 1000;
            }
        
            &.active {
                .checkbox-background {
                    background-color: .themes[@@checkboxActiveColor];
                }
                
                .checkbox {
                    color: #FFF;
                }

                .outline {
                    color: .themes[@@checkboxActiveColor];
                }

                .check {
                    left: .05rem;
                    width: .75rem;
                }
            }
        }

        label {
            font-weight: 400;
            margin: auto 0 auto .25rem;
            user-select: none;
            cursor: pointer;
        }

        &.invalid {
            color: red;
        }

        &.disabled {
            color: .themes[@@gray6];
            
            label {
                cursor: default;
            }

            .checkbox-wrapper { 
                cursor: default;
            }

            .checkbox-wrapper.active {
                .checkbox-background {
                    background-color: .themes[@@gray6];
                }

                .outline {
                    color: .themes[@@gray6];
                }
            }
        }
    }
}