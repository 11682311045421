.structure() {

    & {
        .window-fill();
        background-color: .themes[@@background1];
    }

    & > .left-nav {
        width: 5.5rem;
        padding: 4.5rem 0 0 0;
        background-color: .themes[@@left-nav-background];
        background-image: url(http://localhost:8080/images/a3-logo.png);
        background-repeat: no-repeat;
        background-position: center .75rem;
        background-size: 2.5rem;
    }
    
    & > main {
        display: flex;
        flex: 1;
        flex-direction: column;
    }
    
    & > main > header {
        background-color: .themes[@@background1];
        border-bottom: 1px solid .themes[@@app-header-border];
    }

    & > main > section {
        flex: 1;
        background-color: .themes[@@background1];
        overflow: auto;
    }

    & > main > footer {
        background-color: .themes[@@background1];
        text-align: center;
        font-size: .75rem;
        width: 100%;
        height: 3rem;
        border-top: 1px solid .themes[@@app-header-border];
    }

    .flex { display: flex; }
    .block { display: block; }
    .inline-block { display: inline-block; }

    .column { flex-direction: column; }
    .space-between { justify-content: space-between; }
    .space-around { justify-content: space-around; }
    .fill { flex: 1; }

    .relative { position: relative; }

    .v-center { 
        margin-top: auto;
        margin-bottom: auto; 
    }
    .h-center {
        margin-left: auto;
        margin-right: auto;
    }

    .w-1 { 
        width: 1rem !important;
    }

    .w-2 {
        width: 2rem !important;
    }

    .w-3 {
        width: 3rem !important;
    }

    .w-4 {
        width: 4rem !important;
    }

    .w-5 {
        width: 5rem !important;
    }

    .w-6 {
        width: 6rem !important;
    }

    .w-7 { 
        width: 7rem !important;
    }

    .w-8 { 
        width: 8rem !important;
    }

    .w-9 { 
        width: 9rem !important;
    }

    .w-10 {
        width: 10rem !important;
    }
    .w-15 {
        width: 15rem !important;
    }
    .w-20 {
        width: 20rem !important;
    }
    .w-30 {
        width: 30rem !important;
    }
    .w-40 {
        width: 40rem !important;
    }
    .w-50 {
        width: 50rem !important;
    }

    .w-25-p {
        width: 25% !important;
    }
    .w-50-p {
        width: 50% !important;
    }
    .w-75-p {
        width: 75% !important;
    }    

    .w-fill { 
        width: 100% !important;
    }

    .max-w-10 {
        max-width: 10rem !important;
    }
    .max-w-20 {
        max-width: 20rem !important;
    }
    .max-w-30 {
        max-width: 30rem !important;
    }
    .max-w-40 {
        max-width: 40rem !important;
    }
    .max-w-50 {
        max-width: 50rem !important;
    }

    .max-w-25-p {
        max-width: 25% !important;
    }
    .max-w-50-p {
        max-width: 50% !important;
    }
    .max-w-75-p {
        max-width: 75% !important;
    }

    .window-fill {
        .window-fill();
    }
    
    .window-fill(){
        display: flex;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    };

    pre { 
        margin: 0;
    }

    .sticky-footer {
        position: fixed;
        bottom: .5rem;
        left: 5.5rem;
        right: 0;
        padding: 0 2rem 0 2rem;
    }
}