@import 'mixins.less';

.mt-auto { margin-top: auto; }
.mr-auto { margin-right: auto; }
.mb-auto { margin-bottom: auto; }
.ml-auto { margin-left: auto; }
.m-auto { margin: auto; }

.margins(@defaultFactor);
.paddings(@defaultFactor);
 