/*
    Theming
    ------------------------------------------------------------------------------------
    To create a new theme style do the following...

    - Create a new theme less file and import it below
        - contents of theme file must be wrapped in a mixin
        - each theme variable must be prefixed with the name of the theme
        - each theme variable must be declared
    - Add theme name to the theme names variable
    - add mixin to .themes()
*/
/*
    Storybook Preview Pane
*/
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/quicksand/v29/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkKEo18E.ttf) format('truetype');
}
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/quicksand/v29/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkP8o18E.ttf) format('truetype');
}
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/quicksand/v29/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkM0o18E.ttf) format('truetype');
}
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/quicksand/v29/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkCEv18E.ttf) format('truetype');
}
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/quicksand/v29/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkBgv18E.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TjARc9.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1Mu52xP.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51S7ABc9.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TzBhc9.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5vAw.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me5Q.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9vAw.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlvAw.ttf) format('truetype');
}
.mt-auto {
  margin-top: auto;
}
.mr-auto {
  margin-right: auto;
}
.mb-auto {
  margin-bottom: auto;
}
.ml-auto {
  margin-left: auto;
}
.m-auto {
  margin: auto;
}
.m-0 {
  margin: 0rem !important;
}
.m-1 {
  margin: 0.5rem !important;
}
.m-2 {
  margin: 1rem !important;
}
.m-3 {
  margin: 1.5rem !important;
}
.m-4 {
  margin: 2rem !important;
}
.m-5 {
  margin: 2.5rem !important;
}
.m-6 {
  margin: 3rem !important;
}
.m-7 {
  margin: 3.5rem !important;
}
.m-8 {
  margin: 4rem !important;
}
.mt-0 {
  margin-top: 0rem !important;
}
.mr-0 {
  margin-right: 0rem !important;
}
.mb-0 {
  margin-bottom: 0rem !important;
}
.ml-0 {
  margin-left: 0rem !important;
}
.mt-1 {
  margin-top: 0.5rem !important;
}
.mr-1 {
  margin-right: 0.5rem !important;
}
.mb-1 {
  margin-bottom: 0.5rem !important;
}
.ml-1 {
  margin-left: 0.5rem !important;
}
.mt-2 {
  margin-top: 1rem !important;
}
.mr-2 {
  margin-right: 1rem !important;
}
.mb-2 {
  margin-bottom: 1rem !important;
}
.ml-2 {
  margin-left: 1rem !important;
}
.mt-3 {
  margin-top: 1.5rem !important;
}
.mr-3 {
  margin-right: 1.5rem !important;
}
.mb-3 {
  margin-bottom: 1.5rem !important;
}
.ml-3 {
  margin-left: 1.5rem !important;
}
.mt-4 {
  margin-top: 2rem !important;
}
.mr-4 {
  margin-right: 2rem !important;
}
.mb-4 {
  margin-bottom: 2rem !important;
}
.ml-4 {
  margin-left: 2rem !important;
}
.mt-5 {
  margin-top: 2.5rem !important;
}
.mr-5 {
  margin-right: 2.5rem !important;
}
.mb-5 {
  margin-bottom: 2.5rem !important;
}
.ml-5 {
  margin-left: 2.5rem !important;
}
.mt-6 {
  margin-top: 3rem !important;
}
.mr-6 {
  margin-right: 3rem !important;
}
.mb-6 {
  margin-bottom: 3rem !important;
}
.ml-6 {
  margin-left: 3rem !important;
}
.mt-7 {
  margin-top: 3.5rem !important;
}
.mr-7 {
  margin-right: 3.5rem !important;
}
.mb-7 {
  margin-bottom: 3.5rem !important;
}
.ml-7 {
  margin-left: 3.5rem !important;
}
.mt-8 {
  margin-top: 4rem !important;
}
.mr-8 {
  margin-right: 4rem !important;
}
.mb-8 {
  margin-bottom: 4rem !important;
}
.ml-8 {
  margin-left: 4rem !important;
}
.p-0 {
  padding: 0rem !important;
}
.p-1 {
  padding: 0.5rem !important;
}
.p-2 {
  padding: 1rem !important;
}
.p-3 {
  padding: 1.5rem !important;
}
.p-4 {
  padding: 2rem !important;
}
.p-5 {
  padding: 2.5rem !important;
}
.p-6 {
  padding: 3rem !important;
}
.p-7 {
  padding: 3.5rem !important;
}
.p-8 {
  padding: 4rem !important;
}
.pt-0 {
  padding-top: 0rem !important;
}
.pr-0 {
  padding-right: 0rem !important;
}
.pb-0 {
  padding-bottom: 0rem !important;
}
.pl-0 {
  padding-left: 0rem !important;
}
.pt-1 {
  padding-top: 0.5rem !important;
}
.pr-1 {
  padding-right: 0.5rem !important;
}
.pb-1 {
  padding-bottom: 0.5rem !important;
}
.pl-1 {
  padding-left: 0.5rem !important;
}
.pt-2 {
  padding-top: 1rem !important;
}
.pr-2 {
  padding-right: 1rem !important;
}
.pb-2 {
  padding-bottom: 1rem !important;
}
.pl-2 {
  padding-left: 1rem !important;
}
.pt-3 {
  padding-top: 1.5rem !important;
}
.pr-3 {
  padding-right: 1.5rem !important;
}
.pb-3 {
  padding-bottom: 1.5rem !important;
}
.pl-3 {
  padding-left: 1.5rem !important;
}
.pt-4 {
  padding-top: 2rem !important;
}
.pr-4 {
  padding-right: 2rem !important;
}
.pb-4 {
  padding-bottom: 2rem !important;
}
.pl-4 {
  padding-left: 2rem !important;
}
.pt-5 {
  padding-top: 2.5rem !important;
}
.pr-5 {
  padding-right: 2.5rem !important;
}
.pb-5 {
  padding-bottom: 2.5rem !important;
}
.pl-5 {
  padding-left: 2.5rem !important;
}
.pt-6 {
  padding-top: 3rem !important;
}
.pr-6 {
  padding-right: 3rem !important;
}
.pb-6 {
  padding-bottom: 3rem !important;
}
.pl-6 {
  padding-left: 3rem !important;
}
.pt-7 {
  padding-top: 3.5rem !important;
}
.pr-7 {
  padding-right: 3.5rem !important;
}
.pb-7 {
  padding-bottom: 3.5rem !important;
}
.pl-7 {
  padding-left: 3.5rem !important;
}
.pt-8 {
  padding-top: 4rem !important;
}
.pr-8 {
  padding-right: 4rem !important;
}
.pb-8 {
  padding-bottom: 4rem !important;
}
.pl-8 {
  padding-left: 4rem !important;
}
.compact .m-0 {
  margin: 0rem !important;
}
.compact .m-1 {
  margin: 0.25rem !important;
}
.compact .m-2 {
  margin: 0.5rem !important;
}
.compact .m-3 {
  margin: 0.75rem !important;
}
.compact .m-4 {
  margin: 1rem !important;
}
.compact .m-5 {
  margin: 1.25rem !important;
}
.compact .m-6 {
  margin: 1.5rem !important;
}
.compact .m-7 {
  margin: 1.75rem !important;
}
.compact .m-8 {
  margin: 2rem !important;
}
.compact .mt-0 {
  margin-top: 0rem !important;
}
.compact .mr-0 {
  margin-right: 0rem !important;
}
.compact .mb-0 {
  margin-bottom: 0rem !important;
}
.compact .ml-0 {
  margin-left: 0rem !important;
}
.compact .mt-1 {
  margin-top: 0.25rem !important;
}
.compact .mr-1 {
  margin-right: 0.25rem !important;
}
.compact .mb-1 {
  margin-bottom: 0.25rem !important;
}
.compact .ml-1 {
  margin-left: 0.25rem !important;
}
.compact .mt-2 {
  margin-top: 0.5rem !important;
}
.compact .mr-2 {
  margin-right: 0.5rem !important;
}
.compact .mb-2 {
  margin-bottom: 0.5rem !important;
}
.compact .ml-2 {
  margin-left: 0.5rem !important;
}
.compact .mt-3 {
  margin-top: 0.75rem !important;
}
.compact .mr-3 {
  margin-right: 0.75rem !important;
}
.compact .mb-3 {
  margin-bottom: 0.75rem !important;
}
.compact .ml-3 {
  margin-left: 0.75rem !important;
}
.compact .mt-4 {
  margin-top: 1rem !important;
}
.compact .mr-4 {
  margin-right: 1rem !important;
}
.compact .mb-4 {
  margin-bottom: 1rem !important;
}
.compact .ml-4 {
  margin-left: 1rem !important;
}
.compact .mt-5 {
  margin-top: 1.25rem !important;
}
.compact .mr-5 {
  margin-right: 1.25rem !important;
}
.compact .mb-5 {
  margin-bottom: 1.25rem !important;
}
.compact .ml-5 {
  margin-left: 1.25rem !important;
}
.compact .mt-6 {
  margin-top: 1.5rem !important;
}
.compact .mr-6 {
  margin-right: 1.5rem !important;
}
.compact .mb-6 {
  margin-bottom: 1.5rem !important;
}
.compact .ml-6 {
  margin-left: 1.5rem !important;
}
.compact .mt-7 {
  margin-top: 1.75rem !important;
}
.compact .mr-7 {
  margin-right: 1.75rem !important;
}
.compact .mb-7 {
  margin-bottom: 1.75rem !important;
}
.compact .ml-7 {
  margin-left: 1.75rem !important;
}
.compact .mt-8 {
  margin-top: 2rem !important;
}
.compact .mr-8 {
  margin-right: 2rem !important;
}
.compact .mb-8 {
  margin-bottom: 2rem !important;
}
.compact .ml-8 {
  margin-left: 2rem !important;
}
.compact .p-0 {
  padding: 0rem !important;
}
.compact .p-1 {
  padding: 0.25rem !important;
}
.compact .p-2 {
  padding: 0.5rem !important;
}
.compact .p-3 {
  padding: 0.75rem !important;
}
.compact .p-4 {
  padding: 1rem !important;
}
.compact .p-5 {
  padding: 1.25rem !important;
}
.compact .p-6 {
  padding: 1.5rem !important;
}
.compact .p-7 {
  padding: 1.75rem !important;
}
.compact .p-8 {
  padding: 2rem !important;
}
.compact .pt-0 {
  padding-top: 0rem !important;
}
.compact .pr-0 {
  padding-right: 0rem !important;
}
.compact .pb-0 {
  padding-bottom: 0rem !important;
}
.compact .pl-0 {
  padding-left: 0rem !important;
}
.compact .pt-1 {
  padding-top: 0.25rem !important;
}
.compact .pr-1 {
  padding-right: 0.25rem !important;
}
.compact .pb-1 {
  padding-bottom: 0.25rem !important;
}
.compact .pl-1 {
  padding-left: 0.25rem !important;
}
.compact .pt-2 {
  padding-top: 0.5rem !important;
}
.compact .pr-2 {
  padding-right: 0.5rem !important;
}
.compact .pb-2 {
  padding-bottom: 0.5rem !important;
}
.compact .pl-2 {
  padding-left: 0.5rem !important;
}
.compact .pt-3 {
  padding-top: 0.75rem !important;
}
.compact .pr-3 {
  padding-right: 0.75rem !important;
}
.compact .pb-3 {
  padding-bottom: 0.75rem !important;
}
.compact .pl-3 {
  padding-left: 0.75rem !important;
}
.compact .pt-4 {
  padding-top: 1rem !important;
}
.compact .pr-4 {
  padding-right: 1rem !important;
}
.compact .pb-4 {
  padding-bottom: 1rem !important;
}
.compact .pl-4 {
  padding-left: 1rem !important;
}
.compact .pt-5 {
  padding-top: 1.25rem !important;
}
.compact .pr-5 {
  padding-right: 1.25rem !important;
}
.compact .pb-5 {
  padding-bottom: 1.25rem !important;
}
.compact .pl-5 {
  padding-left: 1.25rem !important;
}
.compact .pt-6 {
  padding-top: 1.5rem !important;
}
.compact .pr-6 {
  padding-right: 1.5rem !important;
}
.compact .pb-6 {
  padding-bottom: 1.5rem !important;
}
.compact .pl-6 {
  padding-left: 1.5rem !important;
}
.compact .pt-7 {
  padding-top: 1.75rem !important;
}
.compact .pr-7 {
  padding-right: 1.75rem !important;
}
.compact .pb-7 {
  padding-bottom: 1.75rem !important;
}
.compact .pl-7 {
  padding-left: 1.75rem !important;
}
.compact .pt-8 {
  padding-top: 2rem !important;
}
.compact .pr-8 {
  padding-right: 2rem !important;
}
.compact .pb-8 {
  padding-bottom: 2rem !important;
}
.compact .pl-8 {
  padding-left: 2rem !important;
}
.expanded .m-0 {
  margin: 0rem !important;
}
.expanded .m-1 {
  margin: 1rem !important;
}
.expanded .m-2 {
  margin: 2rem !important;
}
.expanded .m-3 {
  margin: 3rem !important;
}
.expanded .m-4 {
  margin: 4rem !important;
}
.expanded .m-5 {
  margin: 5rem !important;
}
.expanded .m-6 {
  margin: 6rem !important;
}
.expanded .m-7 {
  margin: 7rem !important;
}
.expanded .m-8 {
  margin: 8rem !important;
}
.expanded .mt-0 {
  margin-top: 0rem !important;
}
.expanded .mr-0 {
  margin-right: 0rem !important;
}
.expanded .mb-0 {
  margin-bottom: 0rem !important;
}
.expanded .ml-0 {
  margin-left: 0rem !important;
}
.expanded .mt-1 {
  margin-top: 1rem !important;
}
.expanded .mr-1 {
  margin-right: 1rem !important;
}
.expanded .mb-1 {
  margin-bottom: 1rem !important;
}
.expanded .ml-1 {
  margin-left: 1rem !important;
}
.expanded .mt-2 {
  margin-top: 2rem !important;
}
.expanded .mr-2 {
  margin-right: 2rem !important;
}
.expanded .mb-2 {
  margin-bottom: 2rem !important;
}
.expanded .ml-2 {
  margin-left: 2rem !important;
}
.expanded .mt-3 {
  margin-top: 3rem !important;
}
.expanded .mr-3 {
  margin-right: 3rem !important;
}
.expanded .mb-3 {
  margin-bottom: 3rem !important;
}
.expanded .ml-3 {
  margin-left: 3rem !important;
}
.expanded .mt-4 {
  margin-top: 4rem !important;
}
.expanded .mr-4 {
  margin-right: 4rem !important;
}
.expanded .mb-4 {
  margin-bottom: 4rem !important;
}
.expanded .ml-4 {
  margin-left: 4rem !important;
}
.expanded .mt-5 {
  margin-top: 5rem !important;
}
.expanded .mr-5 {
  margin-right: 5rem !important;
}
.expanded .mb-5 {
  margin-bottom: 5rem !important;
}
.expanded .ml-5 {
  margin-left: 5rem !important;
}
.expanded .mt-6 {
  margin-top: 6rem !important;
}
.expanded .mr-6 {
  margin-right: 6rem !important;
}
.expanded .mb-6 {
  margin-bottom: 6rem !important;
}
.expanded .ml-6 {
  margin-left: 6rem !important;
}
.expanded .mt-7 {
  margin-top: 7rem !important;
}
.expanded .mr-7 {
  margin-right: 7rem !important;
}
.expanded .mb-7 {
  margin-bottom: 7rem !important;
}
.expanded .ml-7 {
  margin-left: 7rem !important;
}
.expanded .mt-8 {
  margin-top: 8rem !important;
}
.expanded .mr-8 {
  margin-right: 8rem !important;
}
.expanded .mb-8 {
  margin-bottom: 8rem !important;
}
.expanded .ml-8 {
  margin-left: 8rem !important;
}
.expanded .p-0 {
  padding: 0rem !important;
}
.expanded .p-1 {
  padding: 1rem !important;
}
.expanded .p-2 {
  padding: 2rem !important;
}
.expanded .p-3 {
  padding: 3rem !important;
}
.expanded .p-4 {
  padding: 4rem !important;
}
.expanded .p-5 {
  padding: 5rem !important;
}
.expanded .p-6 {
  padding: 6rem !important;
}
.expanded .p-7 {
  padding: 7rem !important;
}
.expanded .p-8 {
  padding: 8rem !important;
}
.expanded .pt-0 {
  padding-top: 0rem !important;
}
.expanded .pr-0 {
  padding-right: 0rem !important;
}
.expanded .pb-0 {
  padding-bottom: 0rem !important;
}
.expanded .pl-0 {
  padding-left: 0rem !important;
}
.expanded .pt-1 {
  padding-top: 1rem !important;
}
.expanded .pr-1 {
  padding-right: 1rem !important;
}
.expanded .pb-1 {
  padding-bottom: 1rem !important;
}
.expanded .pl-1 {
  padding-left: 1rem !important;
}
.expanded .pt-2 {
  padding-top: 2rem !important;
}
.expanded .pr-2 {
  padding-right: 2rem !important;
}
.expanded .pb-2 {
  padding-bottom: 2rem !important;
}
.expanded .pl-2 {
  padding-left: 2rem !important;
}
.expanded .pt-3 {
  padding-top: 3rem !important;
}
.expanded .pr-3 {
  padding-right: 3rem !important;
}
.expanded .pb-3 {
  padding-bottom: 3rem !important;
}
.expanded .pl-3 {
  padding-left: 3rem !important;
}
.expanded .pt-4 {
  padding-top: 4rem !important;
}
.expanded .pr-4 {
  padding-right: 4rem !important;
}
.expanded .pb-4 {
  padding-bottom: 4rem !important;
}
.expanded .pl-4 {
  padding-left: 4rem !important;
}
.expanded .pt-5 {
  padding-top: 5rem !important;
}
.expanded .pr-5 {
  padding-right: 5rem !important;
}
.expanded .pb-5 {
  padding-bottom: 5rem !important;
}
.expanded .pl-5 {
  padding-left: 5rem !important;
}
.expanded .pt-6 {
  padding-top: 6rem !important;
}
.expanded .pr-6 {
  padding-right: 6rem !important;
}
.expanded .pb-6 {
  padding-bottom: 6rem !important;
}
.expanded .pl-6 {
  padding-left: 6rem !important;
}
.expanded .pt-7 {
  padding-top: 7rem !important;
}
.expanded .pr-7 {
  padding-right: 7rem !important;
}
.expanded .pb-7 {
  padding-bottom: 7rem !important;
}
.expanded .pl-7 {
  padding-left: 7rem !important;
}
.expanded .pt-8 {
  padding-top: 8rem !important;
}
.expanded .pr-8 {
  padding-right: 8rem !important;
}
.expanded .pb-8 {
  padding-bottom: 8rem !important;
}
.expanded .pl-8 {
  padding-left: 8rem !important;
}
.fade-in {
  -webkit-animation: fadeIn 0.25s;
  animation: fadeIn 0.25s;
}
.fade-out {
  -webkit-animation: fadeOut 0.25s;
  animation: fadeOut 0.25s;
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.sb-show-main #root {
  overflow: auto;
  margin: 0 !important;
  width: 100%;
}
.app-container.light {
  /*  the order of the mixins below are important
            as component specific mixins may reference 
            mixins in other files
        */
  font-family: Roboto, sans-serif;
  font-weight: 500;
  letter-spacing: 0.44px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #FFF;
}
.app-container.light .gray1-bg {
  background-color: #F7F9FA;
}
.app-container.light .round {
  border-radius: 0.5rem;
}
.app-container.light .x-round {
  border-radius: 1rem;
}
.app-container.light .xx-round {
  border-radius: 1.5rem;
}
.app-container.light .xxx-round {
  border-radius: 2rem;
}
.app-container.light .elevate-0 {
  box-shadow: none;
}
.app-container.light .elevate-1 {
  box-shadow: 0px 2px 6px rgba(8, 35, 48, 0.16), 0px 1px 2px rgba(8, 35, 48, 0.24);
}
.app-container.light .elevate-2 {
  box-shadow: 0px 4px 8px rgba(8, 35, 48, 0.16), 0px 2px 4px rgba(8, 35, 48, 0.24);
}
.app-container.light .elevate-3 {
  box-shadow: 0px 8px 16px rgba(8, 35, 48, 0.1), 0px 4px 8px rgba(8, 35, 48, 0.16);
}
.app-container.light .elevate-4 {
  box-shadow: 0px 16px 24px rgba(8, 35, 48, 0.08), 0px 6px 12px rgba(8, 35, 48, 0.14);
}
.app-container.light .elevate-5 {
  box-shadow: 0px 24px 32px rgba(8, 35, 48, 0.06), 0px 8px 16px rgba(8, 35, 48, 0.1);
}
.app-container.light .no-wrap {
  white-space: nowrap;
}
.app-container.light h1,
.app-container.light h2,
.app-container.light h3,
.app-container.light h4,
.app-container.light h5,
.app-container.light h6 {
  font-family: Quicksand, Roboto, sans-serif;
}
.app-container.light h1 {
  font-size: 6rem;
  letter-spacing: -1.5px;
  line-height: 120px;
  margin: 0;
}
.app-container.light h2 {
  font-size: 3.75rem;
  letter-spacing: 0px;
  line-height: 75px;
  margin: 0;
}
.app-container.light h3 {
  font-size: 3rem;
  letter-spacing: 0px;
  line-height: 60px;
  margin: 0;
}
.app-container.light h4 {
  font-size: 2.125rem;
  line-height: 42px;
  letter-spacing: 0.25px;
  margin: 0;
}
.app-container.light h5 {
  font-size: 1.5rem;
  letter-spacing: 0px;
  line-height: 30px;
  margin: 0;
}
.app-container.light h6 {
  font-size: 1.25rem;
  letter-spacing: 0.15px;
  line-height: 28px;
  margin: 0;
}
.app-container.light .text-uppercase {
  text-transform: uppercase;
}
.app-container.light .text-standard {
  text-transform: none;
}
.app-container.light .text-center {
  text-align: center;
}
.app-container.light .text-left {
  text-align: left;
}
.app-container.light .text-right {
  text-align: right;
}
.app-container.light .text-small {
  font-size: 0.75rem;
  letter-spacing: 0.25px;
}
.app-container.light .text-large {
  font-size: 1.25rem;
}
.app-container.light .text-x-large {
  font-size: 1.5rem;
}
.app-container.light .text-xx-large {
  font-size: 2rem;
}
.app-container.light .text-xxx-large {
  font-size: 3rem;
}
.app-container.light .text-super-large {
  font-size: 4rem;
}
.app-container.light .text-jumbo-large {
  font-size: 6rem;
}
.app-container.light .text-primary {
  color: #367BF5;
}
.app-container.light .text-danger {
  color: #EA3D2F;
}
.app-container.light .text-warn {
  color: #F3AA18;
}
.app-container.light .text-success {
  color: #2FA84F;
}
.app-container.light .text-accent {
  color: #069697;
}
.app-container.light .text-faint {
  color: #919699;
}
.app-container.light .text-bold {
  font-weight: bold;
}
.app-container.light .text-light {
  font-weight: lighter;
}
.app-container.light .text-italic {
  font-style: italic;
}
.app-container.light b,
.app-container.light strong {
  font-weight: bold;
}
.app-container.light > .left-nav {
  width: 5.5rem;
  padding: 4.5rem 0 0 0;
  background-color: #3E4345;
  background-image: url(http://localhost:8080/images/a3-logo.png);
  background-repeat: no-repeat;
  background-position: center 0.75rem;
  background-size: 2.5rem;
}
.app-container.light > main {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.app-container.light > main > header {
  background-color: #FFF;
  border-bottom: 1px solid #C6CACC;
}
.app-container.light > main > section {
  flex: 1;
  background-color: #FFF;
  overflow: auto;
}
.app-container.light > main > footer {
  background-color: #FFF;
  text-align: center;
  font-size: 0.75rem;
  width: 100%;
  height: 3rem;
  border-top: 1px solid #C6CACC;
}
.app-container.light .flex {
  display: flex;
}
.app-container.light .block {
  display: block;
}
.app-container.light .inline-block {
  display: inline-block;
}
.app-container.light .column {
  flex-direction: column;
}
.app-container.light .space-between {
  justify-content: space-between;
}
.app-container.light .space-around {
  justify-content: space-around;
}
.app-container.light .fill {
  flex: 1;
}
.app-container.light .relative {
  position: relative;
}
.app-container.light .v-center {
  margin-top: auto;
  margin-bottom: auto;
}
.app-container.light .h-center {
  margin-left: auto;
  margin-right: auto;
}
.app-container.light .w-1 {
  width: 1rem !important;
}
.app-container.light .w-2 {
  width: 2rem !important;
}
.app-container.light .w-3 {
  width: 3rem !important;
}
.app-container.light .w-4 {
  width: 4rem !important;
}
.app-container.light .w-5 {
  width: 5rem !important;
}
.app-container.light .w-6 {
  width: 6rem !important;
}
.app-container.light .w-7 {
  width: 7rem !important;
}
.app-container.light .w-8 {
  width: 8rem !important;
}
.app-container.light .w-9 {
  width: 9rem !important;
}
.app-container.light .w-10 {
  width: 10rem !important;
}
.app-container.light .w-15 {
  width: 15rem !important;
}
.app-container.light .w-20 {
  width: 20rem !important;
}
.app-container.light .w-30 {
  width: 30rem !important;
}
.app-container.light .w-40 {
  width: 40rem !important;
}
.app-container.light .w-50 {
  width: 50rem !important;
}
.app-container.light .w-25-p {
  width: 25% !important;
}
.app-container.light .w-50-p {
  width: 50% !important;
}
.app-container.light .w-75-p {
  width: 75% !important;
}
.app-container.light .w-fill {
  width: 100% !important;
}
.app-container.light .max-w-10 {
  max-width: 10rem !important;
}
.app-container.light .max-w-20 {
  max-width: 20rem !important;
}
.app-container.light .max-w-30 {
  max-width: 30rem !important;
}
.app-container.light .max-w-40 {
  max-width: 40rem !important;
}
.app-container.light .max-w-50 {
  max-width: 50rem !important;
}
.app-container.light .max-w-25-p {
  max-width: 25% !important;
}
.app-container.light .max-w-50-p {
  max-width: 50% !important;
}
.app-container.light .max-w-75-p {
  max-width: 75% !important;
}
.app-container.light .window-fill {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.app-container.light pre {
  margin: 0;
}
.app-container.light .sticky-footer {
  position: fixed;
  bottom: 0.5rem;
  left: 5.5rem;
  right: 0;
  padding: 0 2rem 0 2rem;
}
.app-container.light button {
  min-width: 7.5rem;
  display: flex;
  position: relative;
  cursor: pointer;
  user-select: none;
  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  font-family: Roboto, sans-serif;
  line-height: 0.875rem;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 400;
  border-color: transparent;
  box-shadow: none;
  padding: 0 0.703125rem;
  border-radius: 0.25rem;
  border-width: 1px;
  border-style: solid;
  height: 2.5rem;
  width: fit-content;
  justify-content: space-evenly;
}
.app-container.light button.button-x-small {
  height: 1.5rem;
  font-size: 0.6rem;
  min-width: 0rem;
}
.app-container.light button.button-x-small svg {
  font-size: 0.75rem;
}
.app-container.light button.button-small {
  height: 2rem;
  font-size: 0.7rem;
  min-width: 4.5rem;
}
.app-container.light button.button-large {
  height: 3.5rem;
  font-size: 1.25rem;
  letter-spacing: 0;
}
.app-container.light button.button-inline {
  min-width: 0;
  height: auto;
}
.app-container.light button.button-inline span {
  margin: 0;
}
.app-container.light button.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.light button.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.light button.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.light button.primary {
  background-color: #367BF5;
  color: #FFF;
  box-shadow: 0px 4px 8px rgba(54, 123, 245, 0.16), 0px 2px 4px rgba(54, 123, 245, 0.24);
}
.app-container.light button.primary:hover:enabled {
  background-color: #0c5dec;
}
.app-container.light button.primary:active:enabled {
  background-color: #7fabf9;
}
.app-container.light button.primary.link {
  background-color: transparent;
  color: #367BF5;
  box-shadow: none;
  height: auto;
  text-transform: none;
  padding: 0;
}
.app-container.light button.primary.link:hover:enabled {
  color: #094abc;
  background-color: transparent;
}
.app-container.light button.primary.link:active:enabled {
  background-color: transparent;
}
.app-container.light button.primary.link.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.light button.primary.link.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.light button.primary.link.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.light button.primary.smooth {
  background-color: #ecf3fe;
  color: #367BF5;
  font-weight: 500;
}
.app-container.light button.primary.smooth:hover:enabled {
  color: #094abc;
  background-color: #97bbfa;
}
.app-container.light button.primary.smooth:active:enabled {
  background-color: #ffffff;
}
.app-container.light button.primary.smooth.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.light button.primary.smooth.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.light button.primary.smooth.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.light button.primary.outline {
  background-color: #FFF;
  color: #367BF5;
  font-weight: 500;
  border-color: #367BF5;
}
.app-container.light button.primary.outline:hover:enabled {
  color: #094abc;
  background-color: #f8fbff;
}
.app-container.light button.primary.outline:active:enabled {
  background-color: #ffffff;
}
.app-container.light button.primary.outline.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.light button.primary.outline.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.light button.primary.outline.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.light button.primary.outline:hover:enabled {
  border-color: #094abc;
}
.app-container.light button.primary.outline.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.light button.primary.outline.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.light button.primary.outline.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.light button.primary.invert {
  background-color: #FFF;
  color: #367BF5;
  font-weight: 500;
}
.app-container.light button.primary.invert:hover:enabled {
  color: #094abc;
  background-color: #f8fbff;
}
.app-container.light button.primary.invert:active:enabled {
  background-color: #ffffff;
}
.app-container.light button.primary.invert.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.light button.primary.invert.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.light button.primary.invert.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.light button.primary.button-search {
  border-top: 1px solid #E3E5E6;
  border-right: 1px solid #E3E5E6;
  border-bottom: 1px solid #E3E5E6;
  border-left: none;
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
  color: #367BF5;
  min-width: 0;
}
.app-container.light button.primary.button-search:hover:enabled {
  color: #094abc;
  background-color: #f8fbff;
}
.app-container.light button.primary.button-search:active:enabled {
  background-color: #ffffff;
}
.app-container.light button.primary.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.light button.primary.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.light button.primary.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.light button.danger {
  background-color: #EA3D2F;
  color: #FFF;
}
.app-container.light button.danger:hover:enabled {
  background-color: #d12315;
}
.app-container.light button.danger:active:enabled {
  background-color: #f17e74;
}
.app-container.light button.danger.link {
  background-color: transparent;
  color: #EA3D2F;
  box-shadow: none;
  height: auto;
  text-transform: none;
  padding: 0;
}
.app-container.light button.danger.link:hover:enabled {
  color: #a31b10;
  background-color: transparent;
}
.app-container.light button.danger.link:active:enabled {
  background-color: transparent;
}
.app-container.light button.danger.link.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.light button.danger.link.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.light button.danger.link.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.light button.danger.smooth {
  background-color: #fcdfdd;
  color: #EA3D2F;
  font-weight: 500;
}
.app-container.light button.danger.smooth:hover:enabled {
  color: #a31b10;
  background-color: #f3938c;
}
.app-container.light button.danger.smooth:active:enabled {
  background-color: #ffffff;
}
.app-container.light button.danger.smooth.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.light button.danger.smooth.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.light button.danger.smooth.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.light button.danger.outline {
  background-color: #FFF;
  color: #EA3D2F;
  font-weight: 500;
  border-color: #EA3D2F;
}
.app-container.light button.danger.outline:hover:enabled {
  color: #a31b10;
  background-color: #fdeae8;
}
.app-container.light button.danger.outline:active:enabled {
  background-color: #ffffff;
}
.app-container.light button.danger.outline.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.light button.danger.outline.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.light button.danger.outline.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.light button.danger.outline:hover:enabled {
  border-color: #a31b10;
}
.app-container.light button.danger.outline.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.light button.danger.outline.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.light button.danger.outline.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.light button.danger.invert {
  background-color: #FFF;
  color: #EA3D2F;
  font-weight: 500;
}
.app-container.light button.danger.invert:hover:enabled {
  color: #a31b10;
  background-color: #fdeae8;
}
.app-container.light button.danger.invert:active:enabled {
  background-color: #ffffff;
}
.app-container.light button.danger.invert.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.light button.danger.invert.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.light button.danger.invert.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.light button.danger.button-search {
  border-top: 1px solid #E3E5E6;
  border-right: 1px solid #E3E5E6;
  border-bottom: 1px solid #E3E5E6;
  border-left: none;
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
  color: #EA3D2F;
  min-width: 0;
}
.app-container.light button.danger.button-search:hover:enabled {
  color: #a31b10;
  background-color: #fdeae8;
}
.app-container.light button.danger.button-search:active:enabled {
  background-color: #ffffff;
}
.app-container.light button.danger.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.light button.danger.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.light button.danger.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.light button.warn {
  background-color: #F3AA18;
  color: #FFF;
}
.app-container.light button.warn:hover:enabled {
  background-color: #cd8c0b;
}
.app-container.light button.warn:active:enabled {
  background-color: #f7c561;
}
.app-container.light button.warn.link {
  background-color: transparent;
  color: #F3AA18;
  box-shadow: none;
  height: auto;
  text-transform: none;
  padding: 0;
}
.app-container.light button.warn.link:hover:enabled {
  color: #9d6b08;
  background-color: transparent;
}
.app-container.light button.warn.link:active:enabled {
  background-color: transparent;
}
.app-container.light button.warn.link.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.light button.warn.link.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.light button.warn.link.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.light button.warn.smooth {
  background-color: #fcedce;
  color: #F3AA18;
  font-weight: 500;
}
.app-container.light button.warn.smooth:hover:enabled {
  color: #9d6b08;
  background-color: #f8ce79;
}
.app-container.light button.warn.smooth:active:enabled {
  background-color: #ffffff;
}
.app-container.light button.warn.smooth.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.light button.warn.smooth.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.light button.warn.smooth.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.light button.warn.outline {
  background-color: #FFF;
  color: #F3AA18;
  font-weight: 500;
  border-color: #F3AA18;
}
.app-container.light button.warn.outline:hover:enabled {
  color: #9d6b08;
  background-color: #fdf1da;
}
.app-container.light button.warn.outline:active:enabled {
  background-color: #ffffff;
}
.app-container.light button.warn.outline.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.light button.warn.outline.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.light button.warn.outline.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.light button.warn.outline:hover:enabled {
  border-color: #9d6b08;
}
.app-container.light button.warn.outline.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.light button.warn.outline.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.light button.warn.outline.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.light button.warn.invert {
  background-color: #FFF;
  color: #F3AA18;
  font-weight: 500;
}
.app-container.light button.warn.invert:hover:enabled {
  color: #9d6b08;
  background-color: #fdf1da;
}
.app-container.light button.warn.invert:active:enabled {
  background-color: #ffffff;
}
.app-container.light button.warn.invert.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.light button.warn.invert.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.light button.warn.invert.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.light button.warn.button-search {
  border-top: 1px solid #E3E5E6;
  border-right: 1px solid #E3E5E6;
  border-bottom: 1px solid #E3E5E6;
  border-left: none;
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
  color: #F3AA18;
  min-width: 0;
}
.app-container.light button.warn.button-search:hover:enabled {
  color: #9d6b08;
  background-color: #fdf1da;
}
.app-container.light button.warn.button-search:active:enabled {
  background-color: #ffffff;
}
.app-container.light button.warn.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.light button.warn.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.light button.warn.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.light button.accent {
  background-color: #069697;
  color: #FFF;
}
.app-container.light button.accent:hover:enabled {
  background-color: #046566;
}
.app-container.light button.accent:active:enabled {
  background-color: #09dfe1;
}
.app-container.light button.accent.link {
  background-color: transparent;
  color: #069697;
  box-shadow: none;
  height: auto;
  text-transform: none;
  padding: 0;
}
.app-container.light button.accent.link:hover:enabled {
  color: #023535;
  background-color: transparent;
}
.app-container.light button.accent.link:active:enabled {
  background-color: transparent;
}
.app-container.light button.accent.link.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.light button.accent.link.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.light button.accent.link.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.light button.accent.smooth {
  background-color: #63f8f9;
  color: #069697;
  font-weight: 500;
}
.app-container.light button.accent.smooth:hover:enabled {
  color: #023535;
  background-color: #0ef4f5;
}
.app-container.light button.accent.smooth:active:enabled {
  background-color: #d2fdfd;
}
.app-container.light button.accent.smooth.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.light button.accent.smooth.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.light button.accent.smooth.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.light button.accent.outline {
  background-color: #FFF;
  color: #069697;
  font-weight: 500;
  border-color: #069697;
}
.app-container.light button.accent.outline:hover:enabled {
  color: #023535;
  background-color: #70f8f9;
}
.app-container.light button.accent.outline:active:enabled {
  background-color: #d2fdfd;
}
.app-container.light button.accent.outline.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.light button.accent.outline.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.light button.accent.outline.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.light button.accent.outline:hover:enabled {
  border-color: #023535;
}
.app-container.light button.accent.outline.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.light button.accent.outline.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.light button.accent.outline.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.light button.accent.invert {
  background-color: #FFF;
  color: #069697;
  font-weight: 500;
}
.app-container.light button.accent.invert:hover:enabled {
  color: #023535;
  background-color: #70f8f9;
}
.app-container.light button.accent.invert:active:enabled {
  background-color: #d2fdfd;
}
.app-container.light button.accent.invert.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.light button.accent.invert.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.light button.accent.invert.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.light button.accent.button-search {
  border-top: 1px solid #E3E5E6;
  border-right: 1px solid #E3E5E6;
  border-bottom: 1px solid #E3E5E6;
  border-left: none;
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
  color: #069697;
  min-width: 0;
}
.app-container.light button.accent.button-search:hover:enabled {
  color: #023535;
  background-color: #70f8f9;
}
.app-container.light button.accent.button-search:active:enabled {
  background-color: #d2fdfd;
}
.app-container.light button.accent.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.light button.accent.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.light button.accent.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.light button.success {
  background-color: #2FA84F;
  color: #FFF;
}
.app-container.light button.success:hover:enabled {
  background-color: #24803c;
}
.app-container.light button.success:active:enabled {
  background-color: #54cf75;
}
.app-container.light button.success.link {
  background-color: transparent;
  color: #2FA84F;
  box-shadow: none;
  height: auto;
  text-transform: none;
  padding: 0;
}
.app-container.light button.success.link:hover:enabled {
  color: #19582a;
  background-color: transparent;
}
.app-container.light button.success.link:active:enabled {
  background-color: transparent;
}
.app-container.light button.success.link.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.light button.success.link.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.light button.success.link.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.light button.success.smooth {
  background-color: #aee8bd;
  color: #2FA84F;
  font-weight: 500;
}
.app-container.light button.success.smooth:hover:enabled {
  color: #19582a;
  background-color: #68d585;
}
.app-container.light button.success.smooth:active:enabled {
  background-color: #ffffff;
}
.app-container.light button.success.smooth.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.light button.success.smooth.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.light button.success.smooth.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.light button.success.outline {
  background-color: #FFF;
  color: #2FA84F;
  font-weight: 500;
  border-color: #2FA84F;
}
.app-container.light button.success.outline:hover:enabled {
  color: #19582a;
  background-color: #b8ebc5;
}
.app-container.light button.success.outline:active:enabled {
  background-color: #ffffff;
}
.app-container.light button.success.outline.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.light button.success.outline.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.light button.success.outline.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.light button.success.outline:hover:enabled {
  border-color: #19582a;
}
.app-container.light button.success.outline.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.light button.success.outline.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.light button.success.outline.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.light button.success.invert {
  background-color: #FFF;
  color: #2FA84F;
  font-weight: 500;
}
.app-container.light button.success.invert:hover:enabled {
  color: #19582a;
  background-color: #b8ebc5;
}
.app-container.light button.success.invert:active:enabled {
  background-color: #ffffff;
}
.app-container.light button.success.invert.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.light button.success.invert.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.light button.success.invert.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.light button.success.button-search {
  border-top: 1px solid #E3E5E6;
  border-right: 1px solid #E3E5E6;
  border-bottom: 1px solid #E3E5E6;
  border-left: none;
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
  color: #2FA84F;
  min-width: 0;
}
.app-container.light button.success.button-search:hover:enabled {
  color: #19582a;
  background-color: #b8ebc5;
}
.app-container.light button.success.button-search:active:enabled {
  background-color: #ffffff;
}
.app-container.light button.success.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.light button.success.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.light button.success.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.light button.oval {
  border-radius: 6.1875rem;
}
.app-container.light button.circle {
  border-radius: 6.1875rem;
  min-width: 0;
  width: 2.5rem;
}
.app-container.light button.circle.button-small {
  width: 2rem;
}
.app-container.light button.circle.button-x-small {
  width: 1.5rem;
}
.app-container.light button.circle.button-large {
  width: 3.5rem;
}
.app-container.light button.circle.button-large .icon-wrapper {
  margin: auto;
  font-size: 1.5rem;
}
.app-container.light button.elevate {
  box-shadow: 0px 2px 6px rgba(8, 35, 48, 0.16), 0px 1px 2px rgba(8, 35, 48, 0.24);
}
.app-container.light button.elevate:hover {
  box-shadow: 0px 4px 8px rgba(8, 35, 48, 0.16), 0px 2px 4px rgba(8, 35, 48, 0.24);
}
.app-container.light button.elevate:active {
  box-shadow: none;
}
.app-container.light button .badge {
  position: absolute;
  top: -0.25rem;
  right: -1rem;
  border-radius: 10rem;
  padding: 0.25rem 0.2rem 0.15rem 0.2rem;
  background-color: #5E6366;
  color: #FFF;
  font-size: 0.75rem;
  font-weight: 300;
  min-width: 1rem;
}
.app-container.light button .badge.primary {
  background-color: #367BF5;
  color: #FFF;
}
.app-container.light button .badge.danger {
  background-color: #EA3D2F;
  color: #FFF;
}
.app-container.light button .badge.warn {
  background-color: #F3AA18;
  color: #FFF;
}
.app-container.light button .badge.success {
  background-color: #2FA84F;
  color: #FFF;
}
.app-container.light button .icon-wrapper {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 1.125rem;
}
.app-container.light button span {
  padding-top: 2px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.app-container.light button.square {
  flex-direction: column;
  height: 3rem;
  width: 3rem;
  justify-content: space-between;
  min-width: 0;
}
.app-container.light button.square .icon-wrapper {
  font-size: 1.25rem;
  margin: auto;
  display: flex;
}
.app-container.light button.square .badge {
  right: -1.5rem;
}
.app-container.light button.square.button-small {
  width: fit-content;
  height: 1.5rem;
  min-width: 1.5rem;
  font-size: 0.75rem;
  padding: 0;
}
.app-container.light button.square.button-small span {
  margin: auto;
}
.app-container.light button.square.button-small svg {
  font-size: 0.75rem;
}
.app-container.light button.square.button-x-small {
  width: fit-content;
  height: 1.25rem;
  min-width: 1.25rem;
  font-size: 0.75rem;
  padding: 0;
}
.app-container.light button.square.button-x-small span {
  margin: auto;
}
.app-container.light button.tile {
  flex-direction: column;
  min-height: 4rem;
  min-width: 4rem;
  padding: 0;
  justify-content: space-evenly;
  height: auto;
}
.app-container.light button.tile .icon-wrapper {
  margin: 0 auto;
  font-size: 1.25rem;
  padding: 0.75rem;
}
.app-container.light button.tile span:not(.badge) {
  font-size: 0.75rem;
  margin: 0 auto;
  padding: 0;
  text-transform: none;
}
.app-container.light button.tile .badge {
  right: -1.25rem;
}
.app-container.light button.tile.button-small {
  font-size: 0.7rem;
}
.app-container.light button.tile.button-small .icon-wrapper {
  padding: 0.5rem;
}
.app-container.light button.highlight-icon {
  background-color: transparent;
}
.app-container.light button.highlight-icon .icon-wrapper {
  padding: 0.75rem;
  border-radius: 0.2rem;
}
.app-container.light button.highlight-icon:hover .icon-wrapper {
  background-color: #fff;
}
.app-container.light .left-nav > button {
  color: #A6ADAF;
}
.app-container.light .left-nav > button .icon-wrapper {
  color: #FFF;
}
.app-container.light .left-nav > button:hover .icon-wrapper {
  background-color: #5E6366;
}
.app-container.light .left-nav > button:not(.disabled).active {
  color: #FFF;
}
.app-container.light .left-nav > button:not(.disabled).active .icon-wrapper {
  background-color: #FFF;
  color: rgba(0, 0, 0, 0.87);
}
.app-container.light .drag-handle {
  cursor: move;
  user-select: none;
}
.app-container.light select {
  background-color: transparent;
  border: none;
  outline: none;
}
.app-container.light table {
  font-size: 0.875rem;
  border-collapse: collapse;
  width: 100%;
}
.app-container.light table thead tr th {
  color: #919699;
  font-weight: normal;
  border-bottom: 2px solid #E3E5E6;
  text-align: left;
  padding: 0 0.5rem;
  border-top: 2px solid transparent;
  box-sizing: border-box;
  user-select: none;
}
.app-container.light table thead tr th.draggable {
  padding-left: 0;
}
.app-container.light table thead tr th.draggable .table-header-drag-handle {
  visibility: hidden;
  cursor: move;
  margin-right: 0.25rem;
}
.app-container.light table thead tr th.draggable:hover .table-header-drag-handle {
  visibility: visible;
}
.app-container.light table thead tr th .table-sort-button {
  background-color: transparent;
  text-transform: none;
  padding: 0;
  display: inline;
  min-width: 0;
  height: auto;
  color: #367BF5;
}
.app-container.light table thead tr th .table-sort-button span {
  margin: 0;
}
.app-container.light table thead tr th .table-sort-button .icon-wrapper {
  display: inline-block;
  font-size: 0.75rem;
  margin-left: 0.5rem;
}
.app-container.light table tbody {
  border-bottom: 1px solid #E3E5E6;
}
.app-container.light table tbody tr td {
  color: #919699;
  font-weight: normal;
  vertical-align: middle;
  padding: 0.15rem 0.5rem;
}
.app-container.light table input[type=checkbox] {
  border-color: #E3E5E6;
  outline: #E3E5E6;
}
.app-container.light table tbody:hover {
  background-color: #F7F9FA;
}
.app-container.light table .button-column {
  width: 1%;
  white-space: nowrap;
}
.app-container.light table .selecter-column {
  padding-left: 1rem;
  padding-right: 1rem;
  width: 1%;
  white-space: nowrap;
}
.app-container.light table .expander-column {
  padding-left: 1rem;
  padding-right: 1rem;
  cursor: pointer;
  width: 1%;
  white-space: nowrap;
}
.app-container.light table .expander-column + .selecter-column {
  padding-left: 0.5rem;
}
.app-container.light table .expander-column svg {
  transform: rotate(0deg);
  -webkit-transition: transform 0.25s linear;
  -moz-transition: transform 0.25s linear;
  -o-transition: transform 0.25s linear;
  transition: transform 0.25s linear;
}
.app-container.light table .expander-column.active svg {
  transform: rotate(90deg);
}
.app-container.light table .fit-content {
  width: 1%;
  white-space: nowrap;
}
.app-container.light table .expanded-content-row td {
  line-height: 0px;
  -webkit-transition: line-height 0.25s linear;
  -moz-transition: line-height 0.25s linear;
  -o-transition: line-height 0.25s linear;
  transition: line-height 0.25s linear;
  padding: 0;
  overflow: hidden;
}
.app-container.light table .expanded-content-row.active td {
  line-height: 1.5rem;
}
.app-container.light .column-drop-placeholder {
  margin-top: 2px;
  box-sizing: border-box;
  background-color: #edf2f7;
  border: 2px dashed #cbd5e0;
}
.app-container.light .draggable-column-table-container {
  display: flex;
}
.app-container.light .draggable-column-table-container .draggable-column-table-wrapper {
  user-select: none;
  background-color: white;
}
.app-container.light .draggable-column-table-container .draggable-column-table-wrapper th {
  white-space: nowrap;
}
.app-container.light .pagination-row .navigate-button {
  border: 1px solid #C6CACC;
  background: transparent;
  color: #C6CACC;
}
.app-container.light .pagination-row .navigate-button:hover {
  border: 1px solid #5e6569;
  color: #5e6569;
}
.app-container.light .pagination-row .go-to-page-button {
  background: #F7F9FA;
  color: #5E6366;
  font-weight: bold;
}
.app-container.light .pagination-row .go-to-page-button:hover {
  background: #e7edf0;
}
.app-container.light .pagination-row .go-to-page-button.active {
  background: #E3E5E6;
  color: rgba(0, 0, 0, 0.87);
  cursor: initial;
}
.app-container.light .error-detail {
  border: 1px solid #E3E5E6;
  padding: 0.5rem;
}
.app-container.light .checkbox-field-wrapper {
  display: flex;
  font-size: 0.75rem;
}
.app-container.light .checkbox-field-wrapper .checkbox-wrapper {
  position: relative;
  height: 1rem;
  width: 1rem;
  margin: auto 0;
  cursor: pointer;
}
.app-container.light .checkbox-field-wrapper .checkbox-wrapper .checkbox-background {
  top: 0.0625rem;
  border-radius: 0.1875rem;
  width: 0.875rem;
  height: 0.875rem;
  position: absolute;
}
.app-container.light .checkbox-field-wrapper .checkbox-wrapper .checkbox {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1rem;
}
.app-container.light .checkbox-field-wrapper .checkbox-wrapper .check {
  z-index: 1000;
}
.app-container.light .checkbox-field-wrapper .checkbox-wrapper.active .checkbox-background {
  background-color: #367BF5;
}
.app-container.light .checkbox-field-wrapper .checkbox-wrapper.active .checkbox {
  color: #FFF;
}
.app-container.light .checkbox-field-wrapper .checkbox-wrapper.active .outline {
  color: #367BF5;
}
.app-container.light .checkbox-field-wrapper .checkbox-wrapper.active .check {
  left: 0.05rem;
  width: 0.75rem;
}
.app-container.light .checkbox-field-wrapper label {
  font-weight: 400;
  margin: auto 0 auto 0.25rem;
  user-select: none;
  cursor: pointer;
}
.app-container.light .checkbox-field-wrapper.invalid {
  color: red;
}
.app-container.light .checkbox-field-wrapper.disabled {
  color: #919699;
}
.app-container.light .checkbox-field-wrapper.disabled label {
  cursor: default;
}
.app-container.light .checkbox-field-wrapper.disabled .checkbox-wrapper {
  cursor: default;
}
.app-container.light .checkbox-field-wrapper.disabled .checkbox-wrapper.active .checkbox-background {
  background-color: #919699;
}
.app-container.light .checkbox-field-wrapper.disabled .checkbox-wrapper.active .outline {
  color: #919699;
}
.app-container.light .text-field-wrapper {
  position: relative;
  width: 100%;
  height: 3.5rem;
  box-sizing: border-box;
}
.app-container.light .text-field-wrapper label {
  background-color: #FFF;
  position: absolute;
  top: 0rem;
  left: 0.5rem;
  padding: 0 0.15rem;
  font-size: 0.75rem;
  line-height: 1rem;
  z-index: 1000;
  color: #5E6366;
  font-weight: 400;
  user-select: none;
}
.app-container.light .text-field-wrapper.invalid label {
  color: red;
}
.app-container.light .text-field-wrapper input {
  position: absolute;
  left: 0;
  top: 0.5rem;
  width: 100%;
  height: 2rem;
  background-color: transparent;
  padding: 0 0.5rem;
  border: 1px solid #E3E5E6;
  color: #5E6366;
  box-sizing: border-box;
  font-weight: 600;
  padding-top: 0.5rem;
}
.app-container.light .text-field-wrapper input:hover,
.app-container.light .text-field-wrapper input:active,
.app-container.light .text-field-wrapper input:focus {
  outline: none;
}
.app-container.light .text-field-wrapper input::placeholder {
  font-weight: 100;
  font-size: 80%;
  color: #C6CACC;
}
.app-container.light .text-field-wrapper.invalid input {
  color: red;
  border-color: red;
}
.app-container.light .text-field-wrapper.invalid input::placeholder {
  color: red;
}
.app-container.light .text-field-wrapper .error-message {
  color: red;
  font-size: 0.75rem;
  position: absolute;
  left: 0rem;
  bottom: 0;
  line-height: 1rem;
  width: 100%;
  text-align: right;
  font-weight: 400;
}
.app-container.light .text-field-wrapper.inline-label {
  display: flex;
  height: 2rem;
}
.app-container.light .text-field-wrapper.inline-label label {
  position: initial;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 1rem;
  white-space: nowrap;
}
.app-container.light .text-field-wrapper.inline-label input {
  position: initial;
  margin-top: auto;
  margin-bottom: auto;
  top: unset;
  padding-top: 0;
}
.app-container.light .text-field-wrapper.inline-label .error-message {
  box-sizing: border-box;
  bottom: 0.45rem;
  padding-right: 0.5rem;
}
.app-container.light .text-field-wrapper.inline-validation.inline-label {
  height: auto;
}
.app-container.light .text-field-wrapper.text-small input {
  font-size: 0.75rem;
  letter-spacing: 0.25px;
}
.app-container.light .text-field-wrapper.on-blackout input {
  color: #FFF;
}
.app-container.light .text-field-wrapper.disabled input {
  background-color: #F1F3F4;
  color: #919699;
}
.app-container.light .text-field-wrapper.disabled label {
  background-color: transparent;
  color: #919699;
}
.app-container.light .address-field-wrapper {
  position: relative;
  height: 6.7rem;
  width: 100%;
  box-sizing: border-box;
}
.app-container.light .address-field-wrapper .same-as-checkbox {
  position: absolute;
  top: -0.5rem;
  right: 0.5rem;
  padding: 0 0.25rem;
  display: flex;
  background: #fff;
  font-weight: 100;
}
.app-container.light .address-field-wrapper .same-as-checkbox label {
  line-height: 1rem;
}
.app-container.light .address-field-wrapper .address-field-border-wrapper {
  position: absolute;
  width: 100%;
  top: 0.5rem;
  left: 0rem;
  border: 1px solid #E3E5E6;
  box-sizing: border-box;
  padding-top: 0.5rem;
}
.app-container.light .address-field-wrapper .address-field-border-wrapper > label {
  background-color: #FFF;
  position: absolute;
  top: -0.5rem;
  left: 0.5rem;
  padding: 0 0.15rem;
  font-size: 0.75rem;
  line-height: 1rem;
  z-index: 1000;
  color: #5E6366;
  font-weight: 400;
  user-select: none;
}
.app-container.light .address-field-wrapper .address-field-border-wrapper.invalid label {
  color: red;
}
.app-container.light .address-field-wrapper .address-field-border-wrapper.invalid {
  border-color: red;
}
.app-container.light .address-field-wrapper .address-field-border-wrapper .text-field-wrapper.invalid > input {
  border-color: red;
  color: red;
}
.app-container.light .address-field-wrapper .address-field-border-wrapper .text-field-wrapper.invalid > input::placeholder {
  color: red;
}
.app-container.light .address-field-wrapper .address-field-border-wrapper .text-field-wrapper {
  height: 1.5rem;
}
.app-container.light .address-field-wrapper .address-field-border-wrapper input {
  box-sizing: border-box;
  margin: 0;
  height: 1.5rem;
  border: none;
  background-color: transparent;
  padding: 0 0.5rem;
  color: #5E6366;
  font-weight: 600;
}
.app-container.light .address-field-wrapper .address-field-border-wrapper input:hover,
.app-container.light .address-field-wrapper .address-field-border-wrapper input:active,
.app-container.light .address-field-wrapper .address-field-border-wrapper input:focus {
  outline: none;
}
.app-container.light .address-field-wrapper .address-field-border-wrapper input::placeholder {
  font-weight: 100;
  font-size: 80%;
  color: #C6CACC;
}
.app-container.light .address-field-wrapper .address-field-border-wrapper > input {
  width: 100%;
}
.app-container.light .address-field-wrapper .address-field-border-wrapper .city-field {
  flex: 1;
}
.app-container.light .address-field-wrapper .address-field-border-wrapper .state-field {
  width: 2.5rem;
  height: 1.5rem;
}
.app-container.light .address-field-wrapper .address-field-border-wrapper .state-field input {
  padding: 0;
  text-align: center;
}
.app-container.light .address-field-wrapper .address-field-border-wrapper .state-field .dropdown-content {
  top: 1.5rem;
  left: unset;
  right: -0.75rem;
}
.app-container.light .address-field-wrapper .address-field-border-wrapper .zip-field {
  width: 6rem;
}
.app-container.light .address-field-wrapper .address-field-border-wrapper .zip-field input {
  text-align: center;
}
.app-container.light .address-field-wrapper .error-message {
  color: red;
  font-size: 0.75rem;
  position: absolute;
  left: 0;
  bottom: 0;
  line-height: 1rem;
  width: 100%;
  text-align: right;
  font-weight: 400;
}
.app-container.light .address-field-wrapper.disabled .same-as-checkbox {
  background-color: transparent;
}
.app-container.light .address-field-wrapper.disabled .address-field-border-wrapper {
  background-color: #F1F3F4;
  color: #919699;
}
.app-container.light .address-field-wrapper.disabled .address-field-border-wrapper label {
  background-color: transparent;
  color: #919699;
}
.app-container.light .dropdown-wrapper {
  position: relative;
  width: 100%;
  height: 3.5rem;
  box-sizing: border-box;
}
.app-container.light .dropdown-wrapper label {
  background-color: #FFF;
  position: absolute;
  top: 0rem;
  left: 0.5rem;
  padding: 0 0.15rem;
  font-size: 0.75rem;
  line-height: 1rem;
  z-index: 1000;
  color: #5E6366;
  font-weight: 400;
  user-select: none;
}
.app-container.light .dropdown-wrapper.invalid label {
  color: red;
}
.app-container.light .dropdown-wrapper input {
  position: absolute;
  left: 0;
  top: 0.5rem;
  width: 100%;
  height: 2rem;
  background-color: transparent;
  padding: 0 0.5rem;
  border: 1px solid #E3E5E6;
  color: #5E6366;
  box-sizing: border-box;
  font-weight: 600;
  padding-top: 0.5rem;
  cursor: pointer;
  caret-color: transparent;
}
.app-container.light .dropdown-wrapper input:hover,
.app-container.light .dropdown-wrapper input:active,
.app-container.light .dropdown-wrapper input:focus {
  outline: none;
}
.app-container.light .dropdown-wrapper input::placeholder {
  font-weight: 100;
  font-size: 80%;
  color: #C6CACC;
}
.app-container.light .dropdown-wrapper.invalid input {
  color: red;
  border-color: red;
}
.app-container.light .dropdown-wrapper.invalid input::placeholder {
  color: red;
}
.app-container.light .dropdown-wrapper .error-message {
  color: red;
  font-size: 0.75rem;
  position: absolute;
  left: 0rem;
  bottom: 0;
  line-height: 1rem;
  width: 100%;
  text-align: right;
  font-weight: 400;
}
.app-container.light .dropdown-wrapper .dropdown-caret {
  position: absolute;
  color: #5E6366;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
}
.app-container.light .dropdown-wrapper .dropdown-content {
  display: none;
  position: absolute;
  background-color: #FFF;
  z-index: 1100;
  top: 2.5rem;
  border: 1px solid #E3E5E6;
  border-top: none;
  box-sizing: border-box;
  max-height: 20rem;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 100%;
  width: auto;
}
.app-container.light .dropdown-wrapper .dropdown-content .option {
  padding: 0.25rem 0.5rem;
  width: 100%;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1rem;
  box-sizing: border-box;
  cursor: pointer;
}
.app-container.light .dropdown-wrapper .dropdown-content .option:hover {
  background-color: #F7F9FA;
}
.app-container.light .dropdown-wrapper .dropdown-content .option.active {
  background-color: #F7F9FA;
}
.app-container.light .dropdown-wrapper.active input {
  border-bottom: none;
}
.app-container.light .dropdown-wrapper.active .dropdown-content {
  display: block;
}
.app-container.light .dropdown-wrapper.invalid .dropdown-content {
  border-color: red;
}
.app-container.light .dropdown-wrapper.field-small {
  height: 2.5rem;
  font-size: 0.75rem;
  letter-spacing: 0.25px;
}
.app-container.light .dropdown-wrapper.field-small input {
  padding-top: 0;
  height: 2.5rem;
}
.app-container.light .dropdown-wrapper.field-small svg {
  right: 0.75rem;
}
.app-container.light .dropdown-wrapper.field-small .dropdown-content {
  top: 2rem;
}
.app-container.light .dropdown-wrapper.field-x-small {
  height: 1.75rem;
  font-size: 0.75rem;
  letter-spacing: 0.25px;
}
.app-container.light .dropdown-wrapper.field-x-small input {
  padding-top: 0;
  height: 1.75rem;
}
.app-container.light .dropdown-wrapper.field-x-small svg {
  right: 0.5rem;
}
.app-container.light .dropdown-wrapper.field-x-small .dropdown-content {
  top: 1.75rem;
}
.app-container.light .dropdown-wrapper.no-label input {
  top: 0;
}
.app-container.light .dropdown-wrapper.no-label .dropdown-caret {
  top: 0.5rem;
}
.app-container.light .dropdown-wrapper.inline-label {
  display: flex;
  height: 2rem;
}
.app-container.light .dropdown-wrapper.inline-label label {
  position: initial;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 1rem;
  white-space: nowrap;
}
.app-container.light .dropdown-wrapper.inline-label input {
  position: initial;
  margin-top: auto;
  margin-bottom: auto;
  top: unset;
  padding-top: 0;
}
.app-container.light .dropdown-wrapper.inline-label .dropdown-caret {
  top: 0.5rem;
}
.app-container.light .dropdown-wrapper.inline-label .error-message {
  box-sizing: border-box;
  bottom: 0.45rem;
  padding-right: 0.5rem;
}
.app-container.light .dropdown-wrapper.inline-label .dropdown-content {
  top: 2rem;
}
.app-container.light .dropdown-wrapper.text-small input {
  font-size: 0.75rem;
  letter-spacing: 0.25px;
}
.app-container.light .dropdown-wrapper.drop-up .dropdown-content {
  bottom: 1.75rem;
  top: unset;
  border-bottom: none;
  border-top: 1px solid #E3E5E6;
}
.app-container.light .dropdown-wrapper.drop-up.active input {
  border-bottom: 1px solid #E3E5E6;
  border-top: none;
}
.app-container.light .dropdown-wrapper.disabled input {
  background-color: #F1F3F4;
  color: #919699;
  cursor: default;
}
.app-container.light .dropdown-wrapper.disabled label {
  background-color: transparent;
  color: #919699;
}
.app-container.light .dropdown-wrapper.disabled .dropdown-caret {
  display: none;
}
.app-container.light .radio-field-wrapper {
  user-select: none;
  display: flex;
}
.app-container.light .radio-field-wrapper .radio-field-label {
  margin-top: auto;
  margin-bottom: auto;
}
.app-container.light .radio-field-wrapper .option-label {
  margin-top: auto;
  margin-bottom: auto;
}
.app-container.light .radio-field-wrapper .option-label.active span {
  font-weight: bolder;
}
.app-container.light .radio-field-wrapper .option-label span {
  font-weight: 200;
  margin-left: 0.25rem;
}
.app-container.light .radio-field-wrapper input {
  margin-left: 1rem;
}
.app-container.light .radio-field-wrapper.disabled .radio-field-label {
  color: #919699;
}
.app-container.light .radio-field-wrapper.disabled .option-label {
  color: #919699;
}
.app-container.light .tab-group .tab-heading {
  border-bottom: 1px solid #E3E5E6;
  user-select: none;
}
.app-container.light .tab-group .tab-heading .tab-button-wrapper {
  display: flex;
  min-height: 2rem;
}
.app-container.light .tab-group .tab-heading .tab-button-wrapper .tab-button {
  width: 8rem;
}
.app-container.light .tab-group .tab-heading .tab-button-indicator-wrapper {
  position: relative;
}
.app-container.light .tab-group .tab-heading .tab-button-indicator-wrapper .tab-button-indicator {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 0.125rem;
  width: 8rem;
  background-color: #367BF5;
  -webkit-transition: left 0.25s ease-out;
  -moz-transition: left 0.25s ease-out;
  -o-transition: left 0.25s ease-out;
  transition: left 0.25s ease-out;
}
.app-container.light .tab-group .tab-heading .tab-button-indicator-wrapper .tab-button-indicator.active-0 {
  left: 0;
}
.app-container.light .tab-group .tab-heading .tab-button-indicator-wrapper .tab-button-indicator.active-1 {
  left: 8rem;
}
.app-container.light .tab-group .tab-heading .tab-button-indicator-wrapper .tab-button-indicator.active-2 {
  left: 16rem;
}
.app-container.light .tab-group .tab-heading .tab-button-indicator-wrapper .tab-button-indicator.active-3 {
  left: 24rem;
}
.app-container.light .tab-group .tab-heading .tab-button-indicator-wrapper .tab-button-indicator.active-4 {
  left: 32rem;
}
.app-container.light .tab-group .tab-heading .tab-button-indicator-wrapper .tab-button-indicator.active-5 {
  left: 40rem;
}
.app-container.light .tab-group .tab-heading .tab-button-indicator-wrapper .tab-button-indicator.active-6 {
  left: 48rem;
}
.app-container.light .tab-group .tab-heading .tab-button-indicator-wrapper .tab-button-indicator.active-7 {
  left: 56rem;
}
.app-container.light .tab-group .tab-heading .tab-button-indicator-wrapper .tab-button-indicator.active-8 {
  left: 64rem;
}
.app-container.light .tab-group .tab-heading .tab-button-indicator-wrapper .tab-button-indicator.active-9 {
  left: 72rem;
}
.app-container.light .tab-group .tab-heading .tab-button-indicator-wrapper .tab-button-indicator.active-10 {
  left: 80rem;
}
.app-container.light .tab-group .tab-heading .tab-button-indicator-wrapper .tab-button-indicator.active-11 {
  left: 88rem;
}
.app-container.light .tab-group .tab-content {
  box-sizing: border-box;
}
.app-container.light .tab-group .tab-content .tab {
  width: 100%;
  box-sizing: border-box;
  opacity: 0;
  display: none;
}
.app-container.light .tab-group .tab-content .tab.active {
  display: block;
  opacity: 1;
  -webkit-animation: fadeIn 0.25s;
  animation: fadeIn 0.25s;
}
.app-container.light .chip {
  display: flex;
  position: relative;
  font-size: 0.875rem;
  color: #5E6366;
  border-radius: 50rem;
  padding: 0.375rem 0.875rem;
  width: fit-content;
  height: 2.25rem;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 1.25rem;
  background-color: #F1F3F4;
  box-sizing: border-box;
  user-select: none;
}
.app-container.light .chip.outline {
  background-color: transparent;
  border: 1px solid #E3E5E6;
}
.app-container.light .chip.clickable {
  cursor: pointer;
}
.app-container.light .chip.elevate {
  background-color: transparent;
  box-shadow: 0px 2px 6px rgba(8, 35, 48, 0.16), 0px 1px 2px rgba(8, 35, 48, 0.24);
}
.app-container.light .chip.elevate.clickable {
  -webkit-transition: box-shadow 0.25s ease-out;
  -moz-transition: box-shadow 0.25s ease-out;
  -o-transition: box-shadow 0.25s ease-out;
  transition: box-shadow 0.25s ease-out;
}
.app-container.light .chip.elevate.clickable:hover {
  box-shadow: 0px 4px 8px rgba(8, 35, 48, 0.16), 0px 2px 4px rgba(8, 35, 48, 0.24);
}
.app-container.light .chip.elevate.clickable:active {
  box-shadow: none;
}
.app-container.light .chip.primary {
  color: #367BF5;
}
.app-container.light .chip.primary.outline {
  border-color: #367BF5;
}
.app-container.light .chip.danger {
  color: #EA3D2F;
}
.app-container.light .chip.danger.outline {
  border-color: #EA3D2F;
}
.app-container.light .chip.accent {
  color: #069697;
}
.app-container.light .chip.accent.outline {
  border-color: #069697;
}
.app-container.light .chip.success {
  color: #2FA84F;
}
.app-container.light .chip.success.outline {
  border-color: #2FA84F;
}
.app-container.light .chip.chip-small {
  font-size: 0.7rem;
  padding: 0.125rem 0.5rem;
  line-height: 0.75rem;
  height: 1.5rem;
  letter-spacing: 1px;
  font-weight: 500;
}
.app-container.light .chip.chip-small .icon-wrapper {
  font-size: 0.65rem;
  margin-top: 0.35rem;
}
.app-container.light .chip.chip-small .icon-wrapper:first-of-type {
  margin-left: 0.25rem;
  margin-right: 0.125rem;
}
.app-container.light .chip.chip-small .icon-wrapper:last-of-type {
  margin-left: 0.125rem;
  margin-right: 0.25rem;
}
.app-container.light .chip.chip-small span {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.app-container.light .chip.chip-small.outline .icon-wrapper {
  margin-top: 0.3rem;
}
.app-container.light .chip .icon-wrapper {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 0.75rem;
}
.app-container.light .chip span {
  padding-top: 2px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.app-container.light .toggle-button {
  display: flex;
  width: fit-content;
  border: 1px solid #E3E5E6;
  border-radius: 0.25rem;
  font-weight: 400;
  user-select: none;
}
.app-container.light .toggle-button .toggle-button-option {
  flex: 1;
  text-align: center;
  padding: 0.25rem 1rem;
  cursor: pointer;
  box-sizing: border-box;
  border-collapse: collapse;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.app-container.light .toggle-button .toggle-button-option:hover {
  background-color: #F1F3F4;
  border-color: #F1F3F4;
}
.app-container.light .toggle-button .toggle-button-option.active {
  background-color: #919699;
  border-color: #919699;
  color: white;
}
.app-container.light .toggle-button .toggle-button-option + .toggle-button-option {
  border-left: 1px solid #E3E5E6;
}
.app-container.light .toggle-button .toggle-button-option:last-of-type {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.app-container.light .toggle-button .toggle-button-option:first-of-type {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.app-container.light .toggle-button.primary .toggle-button-option.active:hover {
  color: #094abc;
  background-color: #f8fbff;
}
.app-container.light .toggle-button.primary .toggle-button-option.active.active {
  background-color: #367BF5;
  border-color: #367BF5;
  color: white;
}
.app-container.light .toggle-button.danger .toggle-button-option.active:hover {
  color: #a31b10;
  background-color: #fdeae8;
}
.app-container.light .toggle-button.danger .toggle-button-option.active.active {
  background-color: #EA3D2F;
  border-color: #EA3D2F;
  color: white;
}
.app-container.light .toggle-button.warn .toggle-button-option.active:hover {
  color: #9d6b08;
  background-color: #fdf1da;
}
.app-container.light .toggle-button.warn .toggle-button-option.active.active {
  background-color: #F3AA18;
  border-color: #F3AA18;
  color: white;
}
.app-container.light .toggle-button.success .toggle-button-option.active:hover {
  color: #19582a;
  background-color: #b8ebc5;
}
.app-container.light .toggle-button.success .toggle-button-option.active.active {
  background-color: #2FA84F;
  border-color: #2FA84F;
  color: white;
}
.app-container.light .toggle-button.accent .toggle-button-option.active:hover {
  color: #023535;
  background-color: #70f8f9;
}
.app-container.light .toggle-button.accent .toggle-button-option.active.active {
  background-color: #069697;
  border-color: #069697;
  color: white;
}
.app-container.light .toggle-button.toggle-button-small .toggle-button-option {
  padding: 0.125rem 0.5rem;
  font-size: 0.75rem;
}
.app-container.light .toggle-button.toggle-button-x-small .toggle-button-option {
  padding: 0rem 0.25rem;
  font-size: 0.65rem;
}
.app-container.light .toggle-button.elevate {
  box-shadow: 0px 2px 6px rgba(8, 35, 48, 0.16), 0px 1px 2px rgba(8, 35, 48, 0.24);
}
.app-container.light .query-builder-condition-set {
  width: fit-content;
  box-sizing: border-box;
  position: relative;
}
.app-container.light .query-builder-condition-set .query-builder-condition-set-pointer {
  border-top: 1px dashed #C6CACC;
  position: absolute;
  top: 1rem;
  left: -1rem;
  width: 1rem;
}
.app-container.light .query-builder-condition-set .query-builder-condition-container {
  border-left: 1px dashed #C6CACC;
  margin-left: 1rem;
  box-sizing: border-box;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 1rem;
}
.app-container.light .query-builder-condition-set .query-builder-condition-container .query-builder-condition {
  position: relative;
  display: flex;
  height: 3rem;
  margin-top: 1rem;
  margin-left: 1rem;
  box-sizing: border-box;
  box-shadow: 0px 2px 6px rgba(8, 35, 48, 0.16), 0px 1px 2px rgba(8, 35, 48, 0.24);
  width: calc(100% - 1rem);
}
.app-container.light .query-builder-condition-set .query-builder-condition-container .query-builder-condition > span {
  margin-top: auto;
  margin-bottom: auto;
}
.app-container.light .query-builder-condition-set .query-builder-condition-container .query-builder-condition:first-of-type {
  margin-top: 0;
}
.app-container.light .query-builder-condition-set .query-builder-condition-container .query-builder-condition .query-builder-condition-pointer {
  border-top: 1px dashed #C6CACC;
  position: absolute;
  top: 1.5rem;
  left: -1rem;
  width: 1rem;
}
.app-container.light .query-builder-condition-set .query-builder-condition-set {
  margin-top: 1rem;
  margin-left: 1rem;
}
.app-container.light .query-builder-condition-set .add-condition-button {
  margin-left: 0.25rem;
  margin-top: 0.25rem;
}
.app-container.light .menu {
  opacity: 0;
  background-color: #FFF;
  z-index: 1001;
  height: 0;
  overflow: hidden;
  box-shadow: 0px 4px 8px rgba(8, 35, 48, 0.16), 0px 2px 4px rgba(8, 35, 48, 0.24);
  user-select: none;
  -webkit-transition: opacity 0.1s ease-out;
  -moz-transition: opacity 0.1s ease-out;
  -o-transition: opacity 0.1s ease-out;
  transition: opacity 0.1s ease-out;
}
.app-container.light .menu.active {
  opacity: 1;
  height: auto;
}
.app-container.light .menu button.menu-button {
  padding: 0.25rem 0.5rem;
  width: 100%;
}
.app-container.light .menu button.menu-button *:last-child {
  margin-right: auto;
}
.app-container.light .menu .menu-item {
  width: 100%;
}
.app-container.light .menu .menu-item:first-of-type button.menu-button {
  padding-top: 0.5rem;
}
.app-container.light .menu .menu-item:last-of-type button.menu-button {
  padding-bottom: 0.5rem;
}
.app-container.light .menu .divider {
  border-top: 1px solid #E3E5E6;
  margin: 0.25rem 0;
}
.app-container.light .menu .menu-item-dragging {
  position: absolute;
  border: 1px solid #E3E5E6;
  background-color: #FFF;
}
.app-container.light .menu-item-drop-placeholder {
  width: 100%;
  margin-top: 2px;
  box-sizing: border-box;
  background-color: #edf2f7;
  border: 2px dashed #cbd5e0;
}
.app-container.light .modal-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
  display: flex;
}
.app-container.light .modal-wrapper .modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2001;
}
.app-container.light .modal-wrapper.backdrop .modal-backdrop {
  background-color: rgba(0, 0, 0, 0.87);
  opacity: 0.5;
}
.app-container.light .modal-wrapper .modal-window {
  background-color: #FFF;
  border-radius: 0.5rem;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  z-index: 2002;
  margin-top: 10rem;
  max-height: calc(100% - 20rem);
  box-shadow: 0px 16px 24px rgba(8, 35, 48, 0.08), 0px 6px 12px rgba(8, 35, 48, 0.14);
  display: flex;
  flex-direction: column;
}
.app-container.light .modal-wrapper .modal-window .header {
  background-color: #F1F3F4;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  padding: 0.5rem 1rem;
  display: flex;
}
.app-container.light .modal-wrapper .modal-window .header .close-button {
  margin-left: auto;
  padding: 0;
  height: auto;
  width: auto;
  min-width: unset;
}
.app-container.light .modal-wrapper .modal-window .content {
  padding: 1rem;
  flex: 1;
  overflow: auto;
}
.app-container.light .modal-wrapper .modal-window .footer {
  border-top: 1px solid #E3E5E6;
  padding: 0.5rem 1rem;
}
.app-container.light .modal-wrapper.blackout .modal-backdrop {
  background-color: rgba(0, 0, 0, 0.87);
  opacity: 1;
}
.app-container.light .modal-wrapper.blackout .modal-window {
  background: transparent;
  color: #F7F9FA;
  border-color: transparent;
  box-shadow: none;
  background-color: transparent;
  border: none;
  outline: none;
}
.app-container.light .modal-wrapper.blackout .modal-window .header {
  background: transparent;
}
.app-container.light .modal-wrapper.blackout .modal-window .header .close-button {
  color: #F7F9FA;
}
.app-container.light .modal-wrapper.blackout .modal-window .footer {
  border-top: none;
}
.app-container.light .modal-wrapper.no-header .modal-window .header {
  display: none;
}
.app-container.light .loading-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #FFF;
  z-index: 3000;
  opacity: 0.4;
}
.app-container.light .toast-container {
  position: fixed;
  top: 2rem;
  right: 2rem;
  width: 100%;
  max-width: 25rem;
  z-index: 5000;
}
.app-container.light .toast-container .toast-wrapper + .toast-wrapper {
  margin-top: 0.5rem;
}
.app-container.light .toast-container .toast-wrapper {
  display: flex;
  padding: 0 0 0 0.5rem;
  color: #3E4345;
  border-radius: 0.5rem;
}
.app-container.light .toast-container .toast-wrapper.success {
  border: 1px solid #2FA84F;
  background-color: rgba(47, 168, 79, 0.8);
}
.app-container.light .toast-container .toast-wrapper.danger {
  border: 1px solid #EA3D2F;
  background-color: rgba(234, 61, 47, 0.8);
}
.app-container.light .toast-container .toast-wrapper.accent {
  border: 1px solid #069697;
  background-color: rgba(6, 150, 151, 0.8);
}
.app-container.light .toast-container .toast-wrapper.warn {
  border: 1px solid #F3AA18;
  background-color: rgba(243, 170, 24, 0.8);
}
.app-container.light .toast-container .toast-wrapper.primary {
  border: 1px solid #367BF5;
  background-color: rgba(54, 123, 245, 0.8);
}
.app-container.light .toast-container .toast-message {
  line-height: 1rem;
}
.app-container.light .toast-container .toast-body {
  background-color: #FFF;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  padding: 0.5rem 1rem;
}
.app-container.light .toast-container .close-button {
  height: auto;
  width: auto;
  min-width: unset;
  color: #C6CACC;
  background-color: transparent;
  padding: 0;
}
.app-container.light .toast-container .close-button:hover {
  color: #3E4345;
}
.app-container.light .toast-container .toast-icon {
  color: #F7F9FA;
}
.app-container.light .react-grid-layout {
  position: relative;
  transition: height 200ms ease;
  margin-top: 6px;
  margin-left: -10px;
}
.app-container.light .react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
  box-shadow: 0px 2px 6px rgba(8, 35, 48, 0.16), 0px 1px 2px rgba(8, 35, 48, 0.24);
  box-sizing: border-box;
}
.app-container.light .react-grid-item img {
  pointer-events: none;
  user-select: none;
}
.app-container.light .react-grid-item.cssTransforms {
  transition-property: transform;
}
.app-container.light .react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}
.app-container.light .react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}
.app-container.light .react-grid-item.dropping {
  visibility: hidden;
}
.app-container.light .react-grid-item.react-grid-placeholder {
  background-color: #edf2f7;
  border: 2px dashed #cbd5e0;
  opacity: 0.5;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.app-container.light .react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
}
.app-container.light .react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}
.app-container.light .react-resizable-hide > .react-resizable-handle {
  display: none;
}
.app-container.light .react-grid-item > .react-resizable-handle.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.app-container.light .react-grid-item > .react-resizable-handle.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.app-container.light .react-grid-item > .react-resizable-handle.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.app-container.light .react-grid-item > .react-resizable-handle.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.app-container.light .react-grid-item > .react-resizable-handle.react-resizable-handle-w,
.app-container.light .react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.app-container.light .react-grid-item > .react-resizable-handle.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.app-container.light .react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
.app-container.light .react-grid-item > .react-resizable-handle.react-resizable-handle-n,
.app-container.light .react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.app-container.light .react-grid-item > .react-resizable-handle.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.app-container.light .react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}
.app-container.light .react-resizable {
  position: relative;
}
.app-container.light .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
  background-position: bottom right;
  padding: 0 3px 3px 0;
}
.app-container.light .react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.app-container.light .react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.app-container.light .react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.app-container.light .react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.app-container.light .react-resizable-handle-w,
.app-container.light .react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.app-container.light .react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.app-container.light .react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
.app-container.light .react-resizable-handle-n,
.app-container.light .react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.app-container.light .react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.app-container.light .react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}
.app-container.dark {
  /*  the order of the mixins below are important
            as component specific mixins may reference 
            mixins in other files
        */
  font-family: Roboto, sans-serif;
  font-weight: 500;
  letter-spacing: 0.44px;
  line-height: 24px;
  color: #FFF;
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #3E4345;
}
.app-container.dark .gray1-bg {
  background-color: #F7F9FA;
}
.app-container.dark .round {
  border-radius: 0.5rem;
}
.app-container.dark .x-round {
  border-radius: 1rem;
}
.app-container.dark .xx-round {
  border-radius: 1.5rem;
}
.app-container.dark .xxx-round {
  border-radius: 2rem;
}
.app-container.dark .elevate-0 {
  box-shadow: none;
}
.app-container.dark .elevate-1 {
  box-shadow: 0px 2px 6px rgba(8, 35, 48, 0.16), 0px 1px 2px rgba(8, 35, 48, 0.24);
}
.app-container.dark .elevate-2 {
  box-shadow: 0px 4px 8px rgba(8, 35, 48, 0.16), 0px 2px 4px rgba(8, 35, 48, 0.24);
}
.app-container.dark .elevate-3 {
  box-shadow: 0px 8px 16px rgba(8, 35, 48, 0.1), 0px 4px 8px rgba(8, 35, 48, 0.16);
}
.app-container.dark .elevate-4 {
  box-shadow: 0px 16px 24px rgba(8, 35, 48, 0.08), 0px 6px 12px rgba(8, 35, 48, 0.14);
}
.app-container.dark .elevate-5 {
  box-shadow: 0px 24px 32px rgba(8, 35, 48, 0.06), 0px 8px 16px rgba(8, 35, 48, 0.1);
}
.app-container.dark .no-wrap {
  white-space: nowrap;
}
.app-container.dark h1,
.app-container.dark h2,
.app-container.dark h3,
.app-container.dark h4,
.app-container.dark h5,
.app-container.dark h6 {
  font-family: Quicksand, Roboto, sans-serif;
}
.app-container.dark h1 {
  font-size: 6rem;
  letter-spacing: -1.5px;
  line-height: 120px;
  margin: 0;
}
.app-container.dark h2 {
  font-size: 3.75rem;
  letter-spacing: 0px;
  line-height: 75px;
  margin: 0;
}
.app-container.dark h3 {
  font-size: 3rem;
  letter-spacing: 0px;
  line-height: 60px;
  margin: 0;
}
.app-container.dark h4 {
  font-size: 2.125rem;
  line-height: 42px;
  letter-spacing: 0.25px;
  margin: 0;
}
.app-container.dark h5 {
  font-size: 1.5rem;
  letter-spacing: 0px;
  line-height: 30px;
  margin: 0;
}
.app-container.dark h6 {
  font-size: 1.25rem;
  letter-spacing: 0.15px;
  line-height: 28px;
  margin: 0;
}
.app-container.dark .text-uppercase {
  text-transform: uppercase;
}
.app-container.dark .text-standard {
  text-transform: none;
}
.app-container.dark .text-center {
  text-align: center;
}
.app-container.dark .text-left {
  text-align: left;
}
.app-container.dark .text-right {
  text-align: right;
}
.app-container.dark .text-small {
  font-size: 0.75rem;
  letter-spacing: 0.25px;
}
.app-container.dark .text-large {
  font-size: 1.25rem;
}
.app-container.dark .text-x-large {
  font-size: 1.5rem;
}
.app-container.dark .text-xx-large {
  font-size: 2rem;
}
.app-container.dark .text-xxx-large {
  font-size: 3rem;
}
.app-container.dark .text-super-large {
  font-size: 4rem;
}
.app-container.dark .text-jumbo-large {
  font-size: 6rem;
}
.app-container.dark .text-primary {
  color: #367BF5;
}
.app-container.dark .text-danger {
  color: #EA3D2F;
}
.app-container.dark .text-warn {
  color: #F3AA18;
}
.app-container.dark .text-success {
  color: #2FA84F;
}
.app-container.dark .text-accent {
  color: #069697;
}
.app-container.dark .text-faint {
  color: #919699;
}
.app-container.dark .text-bold {
  font-weight: bold;
}
.app-container.dark .text-light {
  font-weight: lighter;
}
.app-container.dark .text-italic {
  font-style: italic;
}
.app-container.dark b,
.app-container.dark strong {
  font-weight: bold;
}
.app-container.dark > .left-nav {
  width: 5.5rem;
  padding: 4.5rem 0 0 0;
  background-color: #F7F9FA;
  background-image: url(http://localhost:8080/images/a3-logo.png);
  background-repeat: no-repeat;
  background-position: center 0.75rem;
  background-size: 2.5rem;
}
.app-container.dark > main {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.app-container.dark > main > header {
  background-color: #3E4345;
  border-bottom: 1px solid #C6CACC;
}
.app-container.dark > main > section {
  flex: 1;
  background-color: #3E4345;
  overflow: auto;
}
.app-container.dark > main > footer {
  background-color: #3E4345;
  text-align: center;
  font-size: 0.75rem;
  width: 100%;
  height: 3rem;
  border-top: 1px solid #C6CACC;
}
.app-container.dark .flex {
  display: flex;
}
.app-container.dark .block {
  display: block;
}
.app-container.dark .inline-block {
  display: inline-block;
}
.app-container.dark .column {
  flex-direction: column;
}
.app-container.dark .space-between {
  justify-content: space-between;
}
.app-container.dark .space-around {
  justify-content: space-around;
}
.app-container.dark .fill {
  flex: 1;
}
.app-container.dark .relative {
  position: relative;
}
.app-container.dark .v-center {
  margin-top: auto;
  margin-bottom: auto;
}
.app-container.dark .h-center {
  margin-left: auto;
  margin-right: auto;
}
.app-container.dark .w-1 {
  width: 1rem !important;
}
.app-container.dark .w-2 {
  width: 2rem !important;
}
.app-container.dark .w-3 {
  width: 3rem !important;
}
.app-container.dark .w-4 {
  width: 4rem !important;
}
.app-container.dark .w-5 {
  width: 5rem !important;
}
.app-container.dark .w-6 {
  width: 6rem !important;
}
.app-container.dark .w-7 {
  width: 7rem !important;
}
.app-container.dark .w-8 {
  width: 8rem !important;
}
.app-container.dark .w-9 {
  width: 9rem !important;
}
.app-container.dark .w-10 {
  width: 10rem !important;
}
.app-container.dark .w-15 {
  width: 15rem !important;
}
.app-container.dark .w-20 {
  width: 20rem !important;
}
.app-container.dark .w-30 {
  width: 30rem !important;
}
.app-container.dark .w-40 {
  width: 40rem !important;
}
.app-container.dark .w-50 {
  width: 50rem !important;
}
.app-container.dark .w-25-p {
  width: 25% !important;
}
.app-container.dark .w-50-p {
  width: 50% !important;
}
.app-container.dark .w-75-p {
  width: 75% !important;
}
.app-container.dark .w-fill {
  width: 100% !important;
}
.app-container.dark .max-w-10 {
  max-width: 10rem !important;
}
.app-container.dark .max-w-20 {
  max-width: 20rem !important;
}
.app-container.dark .max-w-30 {
  max-width: 30rem !important;
}
.app-container.dark .max-w-40 {
  max-width: 40rem !important;
}
.app-container.dark .max-w-50 {
  max-width: 50rem !important;
}
.app-container.dark .max-w-25-p {
  max-width: 25% !important;
}
.app-container.dark .max-w-50-p {
  max-width: 50% !important;
}
.app-container.dark .max-w-75-p {
  max-width: 75% !important;
}
.app-container.dark .window-fill {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.app-container.dark pre {
  margin: 0;
}
.app-container.dark .sticky-footer {
  position: fixed;
  bottom: 0.5rem;
  left: 5.5rem;
  right: 0;
  padding: 0 2rem 0 2rem;
}
.app-container.dark button {
  min-width: 7.5rem;
  display: flex;
  position: relative;
  cursor: pointer;
  user-select: none;
  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;
  transition: all 0.25s ease-out;
  font-family: Roboto, sans-serif;
  line-height: 0.875rem;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 400;
  border-color: transparent;
  box-shadow: none;
  padding: 0 0.703125rem;
  border-radius: 0.25rem;
  border-width: 1px;
  border-style: solid;
  height: 2.5rem;
  width: fit-content;
  justify-content: space-evenly;
}
.app-container.dark button.button-x-small {
  height: 1.5rem;
  font-size: 0.6rem;
  min-width: 0rem;
}
.app-container.dark button.button-x-small svg {
  font-size: 0.75rem;
}
.app-container.dark button.button-small {
  height: 2rem;
  font-size: 0.7rem;
  min-width: 4.5rem;
}
.app-container.dark button.button-large {
  height: 3.5rem;
  font-size: 1.25rem;
  letter-spacing: 0;
}
.app-container.dark button.button-inline {
  min-width: 0;
  height: auto;
}
.app-container.dark button.button-inline span {
  margin: 0;
}
.app-container.dark button.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.dark button.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.dark button.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.dark button.primary {
  background-color: #367BF5;
  color: #FFF;
  box-shadow: 0px 4px 8px rgba(54, 123, 245, 0.16), 0px 2px 4px rgba(54, 123, 245, 0.24);
}
.app-container.dark button.primary:hover:enabled {
  background-color: #0c5dec;
}
.app-container.dark button.primary:active:enabled {
  background-color: #7fabf9;
}
.app-container.dark button.primary.link {
  background-color: transparent;
  color: #367BF5;
  box-shadow: none;
  height: auto;
  text-transform: none;
  padding: 0;
}
.app-container.dark button.primary.link:hover:enabled {
  color: #094abc;
  background-color: transparent;
}
.app-container.dark button.primary.link:active:enabled {
  background-color: transparent;
}
.app-container.dark button.primary.link.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.dark button.primary.link.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.dark button.primary.link.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.dark button.primary.smooth {
  background-color: #ecf3fe;
  color: #367BF5;
  font-weight: 500;
}
.app-container.dark button.primary.smooth:hover:enabled {
  color: #094abc;
  background-color: #97bbfa;
}
.app-container.dark button.primary.smooth:active:enabled {
  background-color: #ffffff;
}
.app-container.dark button.primary.smooth.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.dark button.primary.smooth.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.dark button.primary.smooth.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.dark button.primary.outline {
  background-color: #FFF;
  color: #367BF5;
  font-weight: 500;
  border-color: #367BF5;
}
.app-container.dark button.primary.outline:hover:enabled {
  color: #094abc;
  background-color: #f8fbff;
}
.app-container.dark button.primary.outline:active:enabled {
  background-color: #ffffff;
}
.app-container.dark button.primary.outline.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.dark button.primary.outline.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.dark button.primary.outline.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.dark button.primary.outline:hover:enabled {
  border-color: #094abc;
}
.app-container.dark button.primary.outline.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.dark button.primary.outline.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.dark button.primary.outline.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.dark button.primary.invert {
  background-color: #FFF;
  color: #367BF5;
  font-weight: 500;
}
.app-container.dark button.primary.invert:hover:enabled {
  color: #094abc;
  background-color: #f8fbff;
}
.app-container.dark button.primary.invert:active:enabled {
  background-color: #ffffff;
}
.app-container.dark button.primary.invert.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.dark button.primary.invert.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.dark button.primary.invert.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.dark button.primary.button-search {
  border-top: 1px solid #E3E5E6;
  border-right: 1px solid #E3E5E6;
  border-bottom: 1px solid #E3E5E6;
  border-left: none;
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
  color: #367BF5;
  min-width: 0;
}
.app-container.dark button.primary.button-search:hover:enabled {
  color: #094abc;
  background-color: #f8fbff;
}
.app-container.dark button.primary.button-search:active:enabled {
  background-color: #ffffff;
}
.app-container.dark button.primary.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.dark button.primary.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.dark button.primary.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.dark button.danger {
  background-color: #EA3D2F;
  color: #FFF;
}
.app-container.dark button.danger:hover:enabled {
  background-color: #d12315;
}
.app-container.dark button.danger:active:enabled {
  background-color: #f17e74;
}
.app-container.dark button.danger.link {
  background-color: transparent;
  color: #EA3D2F;
  box-shadow: none;
  height: auto;
  text-transform: none;
  padding: 0;
}
.app-container.dark button.danger.link:hover:enabled {
  color: #a31b10;
  background-color: transparent;
}
.app-container.dark button.danger.link:active:enabled {
  background-color: transparent;
}
.app-container.dark button.danger.link.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.dark button.danger.link.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.dark button.danger.link.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.dark button.danger.smooth {
  background-color: #fcdfdd;
  color: #EA3D2F;
  font-weight: 500;
}
.app-container.dark button.danger.smooth:hover:enabled {
  color: #a31b10;
  background-color: #f3938c;
}
.app-container.dark button.danger.smooth:active:enabled {
  background-color: #ffffff;
}
.app-container.dark button.danger.smooth.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.dark button.danger.smooth.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.dark button.danger.smooth.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.dark button.danger.outline {
  background-color: #FFF;
  color: #EA3D2F;
  font-weight: 500;
  border-color: #EA3D2F;
}
.app-container.dark button.danger.outline:hover:enabled {
  color: #a31b10;
  background-color: #fdeae8;
}
.app-container.dark button.danger.outline:active:enabled {
  background-color: #ffffff;
}
.app-container.dark button.danger.outline.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.dark button.danger.outline.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.dark button.danger.outline.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.dark button.danger.outline:hover:enabled {
  border-color: #a31b10;
}
.app-container.dark button.danger.outline.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.dark button.danger.outline.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.dark button.danger.outline.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.dark button.danger.invert {
  background-color: #FFF;
  color: #EA3D2F;
  font-weight: 500;
}
.app-container.dark button.danger.invert:hover:enabled {
  color: #a31b10;
  background-color: #fdeae8;
}
.app-container.dark button.danger.invert:active:enabled {
  background-color: #ffffff;
}
.app-container.dark button.danger.invert.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.dark button.danger.invert.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.dark button.danger.invert.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.dark button.danger.button-search {
  border-top: 1px solid #E3E5E6;
  border-right: 1px solid #E3E5E6;
  border-bottom: 1px solid #E3E5E6;
  border-left: none;
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
  color: #EA3D2F;
  min-width: 0;
}
.app-container.dark button.danger.button-search:hover:enabled {
  color: #a31b10;
  background-color: #fdeae8;
}
.app-container.dark button.danger.button-search:active:enabled {
  background-color: #ffffff;
}
.app-container.dark button.danger.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.dark button.danger.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.dark button.danger.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.dark button.warn {
  background-color: #F3AA18;
  color: #FFF;
}
.app-container.dark button.warn:hover:enabled {
  background-color: #cd8c0b;
}
.app-container.dark button.warn:active:enabled {
  background-color: #f7c561;
}
.app-container.dark button.warn.link {
  background-color: transparent;
  color: #F3AA18;
  box-shadow: none;
  height: auto;
  text-transform: none;
  padding: 0;
}
.app-container.dark button.warn.link:hover:enabled {
  color: #9d6b08;
  background-color: transparent;
}
.app-container.dark button.warn.link:active:enabled {
  background-color: transparent;
}
.app-container.dark button.warn.link.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.dark button.warn.link.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.dark button.warn.link.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.dark button.warn.smooth {
  background-color: #fcedce;
  color: #F3AA18;
  font-weight: 500;
}
.app-container.dark button.warn.smooth:hover:enabled {
  color: #9d6b08;
  background-color: #f8ce79;
}
.app-container.dark button.warn.smooth:active:enabled {
  background-color: #ffffff;
}
.app-container.dark button.warn.smooth.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.dark button.warn.smooth.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.dark button.warn.smooth.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.dark button.warn.outline {
  background-color: #FFF;
  color: #F3AA18;
  font-weight: 500;
  border-color: #F3AA18;
}
.app-container.dark button.warn.outline:hover:enabled {
  color: #9d6b08;
  background-color: #fdf1da;
}
.app-container.dark button.warn.outline:active:enabled {
  background-color: #ffffff;
}
.app-container.dark button.warn.outline.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.dark button.warn.outline.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.dark button.warn.outline.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.dark button.warn.outline:hover:enabled {
  border-color: #9d6b08;
}
.app-container.dark button.warn.outline.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.dark button.warn.outline.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.dark button.warn.outline.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.dark button.warn.invert {
  background-color: #FFF;
  color: #F3AA18;
  font-weight: 500;
}
.app-container.dark button.warn.invert:hover:enabled {
  color: #9d6b08;
  background-color: #fdf1da;
}
.app-container.dark button.warn.invert:active:enabled {
  background-color: #ffffff;
}
.app-container.dark button.warn.invert.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.dark button.warn.invert.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.dark button.warn.invert.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.dark button.warn.button-search {
  border-top: 1px solid #E3E5E6;
  border-right: 1px solid #E3E5E6;
  border-bottom: 1px solid #E3E5E6;
  border-left: none;
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
  color: #F3AA18;
  min-width: 0;
}
.app-container.dark button.warn.button-search:hover:enabled {
  color: #9d6b08;
  background-color: #fdf1da;
}
.app-container.dark button.warn.button-search:active:enabled {
  background-color: #ffffff;
}
.app-container.dark button.warn.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.dark button.warn.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.dark button.warn.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.dark button.accent {
  background-color: #069697;
  color: #FFF;
}
.app-container.dark button.accent:hover:enabled {
  background-color: #046566;
}
.app-container.dark button.accent:active:enabled {
  background-color: #09dfe1;
}
.app-container.dark button.accent.link {
  background-color: transparent;
  color: #069697;
  box-shadow: none;
  height: auto;
  text-transform: none;
  padding: 0;
}
.app-container.dark button.accent.link:hover:enabled {
  color: #023535;
  background-color: transparent;
}
.app-container.dark button.accent.link:active:enabled {
  background-color: transparent;
}
.app-container.dark button.accent.link.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.dark button.accent.link.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.dark button.accent.link.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.dark button.accent.smooth {
  background-color: #63f8f9;
  color: #069697;
  font-weight: 500;
}
.app-container.dark button.accent.smooth:hover:enabled {
  color: #023535;
  background-color: #0ef4f5;
}
.app-container.dark button.accent.smooth:active:enabled {
  background-color: #d2fdfd;
}
.app-container.dark button.accent.smooth.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.dark button.accent.smooth.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.dark button.accent.smooth.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.dark button.accent.outline {
  background-color: #FFF;
  color: #069697;
  font-weight: 500;
  border-color: #069697;
}
.app-container.dark button.accent.outline:hover:enabled {
  color: #023535;
  background-color: #70f8f9;
}
.app-container.dark button.accent.outline:active:enabled {
  background-color: #d2fdfd;
}
.app-container.dark button.accent.outline.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.dark button.accent.outline.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.dark button.accent.outline.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.dark button.accent.outline:hover:enabled {
  border-color: #023535;
}
.app-container.dark button.accent.outline.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.dark button.accent.outline.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.dark button.accent.outline.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.dark button.accent.invert {
  background-color: #FFF;
  color: #069697;
  font-weight: 500;
}
.app-container.dark button.accent.invert:hover:enabled {
  color: #023535;
  background-color: #70f8f9;
}
.app-container.dark button.accent.invert:active:enabled {
  background-color: #d2fdfd;
}
.app-container.dark button.accent.invert.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.dark button.accent.invert.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.dark button.accent.invert.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.dark button.accent.button-search {
  border-top: 1px solid #E3E5E6;
  border-right: 1px solid #E3E5E6;
  border-bottom: 1px solid #E3E5E6;
  border-left: none;
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
  color: #069697;
  min-width: 0;
}
.app-container.dark button.accent.button-search:hover:enabled {
  color: #023535;
  background-color: #70f8f9;
}
.app-container.dark button.accent.button-search:active:enabled {
  background-color: #d2fdfd;
}
.app-container.dark button.accent.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.dark button.accent.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.dark button.accent.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.dark button.success {
  background-color: #2FA84F;
  color: #FFF;
}
.app-container.dark button.success:hover:enabled {
  background-color: #24803c;
}
.app-container.dark button.success:active:enabled {
  background-color: #54cf75;
}
.app-container.dark button.success.link {
  background-color: transparent;
  color: #2FA84F;
  box-shadow: none;
  height: auto;
  text-transform: none;
  padding: 0;
}
.app-container.dark button.success.link:hover:enabled {
  color: #19582a;
  background-color: transparent;
}
.app-container.dark button.success.link:active:enabled {
  background-color: transparent;
}
.app-container.dark button.success.link.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.dark button.success.link.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.dark button.success.link.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.dark button.success.smooth {
  background-color: #aee8bd;
  color: #2FA84F;
  font-weight: 500;
}
.app-container.dark button.success.smooth:hover:enabled {
  color: #19582a;
  background-color: #68d585;
}
.app-container.dark button.success.smooth:active:enabled {
  background-color: #ffffff;
}
.app-container.dark button.success.smooth.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.dark button.success.smooth.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.dark button.success.smooth.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.dark button.success.outline {
  background-color: #FFF;
  color: #2FA84F;
  font-weight: 500;
  border-color: #2FA84F;
}
.app-container.dark button.success.outline:hover:enabled {
  color: #19582a;
  background-color: #b8ebc5;
}
.app-container.dark button.success.outline:active:enabled {
  background-color: #ffffff;
}
.app-container.dark button.success.outline.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.dark button.success.outline.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.dark button.success.outline.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.dark button.success.outline:hover:enabled {
  border-color: #19582a;
}
.app-container.dark button.success.outline.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.dark button.success.outline.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.dark button.success.outline.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.dark button.success.invert {
  background-color: #FFF;
  color: #2FA84F;
  font-weight: 500;
}
.app-container.dark button.success.invert:hover:enabled {
  color: #19582a;
  background-color: #b8ebc5;
}
.app-container.dark button.success.invert:active:enabled {
  background-color: #ffffff;
}
.app-container.dark button.success.invert.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.dark button.success.invert.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.dark button.success.invert.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.dark button.success.button-search {
  border-top: 1px solid #E3E5E6;
  border-right: 1px solid #E3E5E6;
  border-bottom: 1px solid #E3E5E6;
  border-left: none;
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
  color: #2FA84F;
  min-width: 0;
}
.app-container.dark button.success.button-search:hover:enabled {
  color: #19582a;
  background-color: #b8ebc5;
}
.app-container.dark button.success.button-search:active:enabled {
  background-color: #ffffff;
}
.app-container.dark button.success.disabled {
  cursor: default;
  color: #C6CACC;
}
.app-container.dark button.success.disabled:hover:disabled {
  color: #C6CACC;
}
.app-container.dark button.success.disabled:active:disabled {
  color: #C6CACC;
}
.app-container.dark button.oval {
  border-radius: 6.1875rem;
}
.app-container.dark button.circle {
  border-radius: 6.1875rem;
  min-width: 0;
  width: 2.5rem;
}
.app-container.dark button.circle.button-small {
  width: 2rem;
}
.app-container.dark button.circle.button-x-small {
  width: 1.5rem;
}
.app-container.dark button.circle.button-large {
  width: 3.5rem;
}
.app-container.dark button.circle.button-large .icon-wrapper {
  margin: auto;
  font-size: 1.5rem;
}
.app-container.dark button.elevate {
  box-shadow: 0px 2px 6px rgba(8, 35, 48, 0.16), 0px 1px 2px rgba(8, 35, 48, 0.24);
}
.app-container.dark button.elevate:hover {
  box-shadow: 0px 4px 8px rgba(8, 35, 48, 0.16), 0px 2px 4px rgba(8, 35, 48, 0.24);
}
.app-container.dark button.elevate:active {
  box-shadow: none;
}
.app-container.dark button .badge {
  position: absolute;
  top: -0.25rem;
  right: -1rem;
  border-radius: 10rem;
  padding: 0.25rem 0.2rem 0.15rem 0.2rem;
  background-color: #5E6366;
  color: #FFF;
  font-size: 0.75rem;
  font-weight: 300;
  min-width: 1rem;
}
.app-container.dark button .badge.primary {
  background-color: #367BF5;
  color: #FFF;
}
.app-container.dark button .badge.danger {
  background-color: #EA3D2F;
  color: #FFF;
}
.app-container.dark button .badge.warn {
  background-color: #F3AA18;
  color: #FFF;
}
.app-container.dark button .badge.success {
  background-color: #2FA84F;
  color: #FFF;
}
.app-container.dark button .icon-wrapper {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 1.125rem;
}
.app-container.dark button span {
  padding-top: 2px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.app-container.dark button.square {
  flex-direction: column;
  height: 3rem;
  width: 3rem;
  justify-content: space-between;
  min-width: 0;
}
.app-container.dark button.square .icon-wrapper {
  font-size: 1.25rem;
  margin: auto;
  display: flex;
}
.app-container.dark button.square .badge {
  right: -1.5rem;
}
.app-container.dark button.square.button-small {
  width: fit-content;
  height: 1.5rem;
  min-width: 1.5rem;
  font-size: 0.75rem;
  padding: 0;
}
.app-container.dark button.square.button-small span {
  margin: auto;
}
.app-container.dark button.square.button-small svg {
  font-size: 0.75rem;
}
.app-container.dark button.square.button-x-small {
  width: fit-content;
  height: 1.25rem;
  min-width: 1.25rem;
  font-size: 0.75rem;
  padding: 0;
}
.app-container.dark button.square.button-x-small span {
  margin: auto;
}
.app-container.dark button.tile {
  flex-direction: column;
  min-height: 4rem;
  min-width: 4rem;
  padding: 0;
  justify-content: space-evenly;
  height: auto;
}
.app-container.dark button.tile .icon-wrapper {
  margin: 0 auto;
  font-size: 1.25rem;
  padding: 0.75rem;
}
.app-container.dark button.tile span:not(.badge) {
  font-size: 0.75rem;
  margin: 0 auto;
  padding: 0;
  text-transform: none;
}
.app-container.dark button.tile .badge {
  right: -1.25rem;
}
.app-container.dark button.tile.button-small {
  font-size: 0.7rem;
}
.app-container.dark button.tile.button-small .icon-wrapper {
  padding: 0.5rem;
}
.app-container.dark button.highlight-icon {
  background-color: transparent;
}
.app-container.dark button.highlight-icon .icon-wrapper {
  padding: 0.75rem;
  border-radius: 0.2rem;
}
.app-container.dark button.highlight-icon:hover .icon-wrapper {
  background-color: #fff;
}
.app-container.dark .left-nav > button {
  color: #A6ADAF;
}
.app-container.dark .left-nav > button .icon-wrapper {
  color: #FFF;
}
.app-container.dark .left-nav > button:hover .icon-wrapper {
  background-color: #5E6366;
}
.app-container.dark .left-nav > button:not(.disabled).active {
  color: #FFF;
}
.app-container.dark .left-nav > button:not(.disabled).active .icon-wrapper {
  background-color: #FFF;
  color: rgba(0, 0, 0, 0.87);
}
.app-container.dark .drag-handle {
  cursor: move;
  user-select: none;
}
.app-container.dark select {
  background-color: transparent;
  border: none;
  outline: none;
}
.app-container.dark table {
  font-size: 0.875rem;
  border-collapse: collapse;
  width: 100%;
}
.app-container.dark table thead tr th {
  color: #919699;
  font-weight: normal;
  border-bottom: 2px solid #E3E5E6;
  text-align: left;
  padding: 0 0.5rem;
  border-top: 2px solid transparent;
  box-sizing: border-box;
  user-select: none;
}
.app-container.dark table thead tr th.draggable {
  padding-left: 0;
}
.app-container.dark table thead tr th.draggable .table-header-drag-handle {
  visibility: hidden;
  cursor: move;
  margin-right: 0.25rem;
}
.app-container.dark table thead tr th.draggable:hover .table-header-drag-handle {
  visibility: visible;
}
.app-container.dark table thead tr th .table-sort-button {
  background-color: transparent;
  text-transform: none;
  padding: 0;
  display: inline;
  min-width: 0;
  height: auto;
  color: #367BF5;
}
.app-container.dark table thead tr th .table-sort-button span {
  margin: 0;
}
.app-container.dark table thead tr th .table-sort-button .icon-wrapper {
  display: inline-block;
  font-size: 0.75rem;
  margin-left: 0.5rem;
}
.app-container.dark table tbody {
  border-bottom: 1px solid #E3E5E6;
}
.app-container.dark table tbody tr td {
  color: #919699;
  font-weight: normal;
  vertical-align: middle;
  padding: 0.15rem 0.5rem;
}
.app-container.dark table input[type=checkbox] {
  border-color: #E3E5E6;
  outline: #E3E5E6;
}
.app-container.dark table tbody:hover {
  background-color: #F7F9FA;
}
.app-container.dark table .button-column {
  width: 1%;
  white-space: nowrap;
}
.app-container.dark table .selecter-column {
  padding-left: 1rem;
  padding-right: 1rem;
  width: 1%;
  white-space: nowrap;
}
.app-container.dark table .expander-column {
  padding-left: 1rem;
  padding-right: 1rem;
  cursor: pointer;
  width: 1%;
  white-space: nowrap;
}
.app-container.dark table .expander-column + .selecter-column {
  padding-left: 0.5rem;
}
.app-container.dark table .expander-column svg {
  transform: rotate(0deg);
  -webkit-transition: transform 0.25s linear;
  -moz-transition: transform 0.25s linear;
  -o-transition: transform 0.25s linear;
  transition: transform 0.25s linear;
}
.app-container.dark table .expander-column.active svg {
  transform: rotate(90deg);
}
.app-container.dark table .fit-content {
  width: 1%;
  white-space: nowrap;
}
.app-container.dark table .expanded-content-row td {
  line-height: 0px;
  -webkit-transition: line-height 0.25s linear;
  -moz-transition: line-height 0.25s linear;
  -o-transition: line-height 0.25s linear;
  transition: line-height 0.25s linear;
  padding: 0;
  overflow: hidden;
}
.app-container.dark table .expanded-content-row.active td {
  line-height: 1.5rem;
}
.app-container.dark .column-drop-placeholder {
  margin-top: 2px;
  box-sizing: border-box;
  background-color: #edf2f7;
  border: 2px dashed #cbd5e0;
}
.app-container.dark .draggable-column-table-container {
  display: flex;
}
.app-container.dark .draggable-column-table-container .draggable-column-table-wrapper {
  user-select: none;
  background-color: white;
}
.app-container.dark .draggable-column-table-container .draggable-column-table-wrapper th {
  white-space: nowrap;
}
.app-container.dark .pagination-row .navigate-button {
  border: 1px solid #E3E5E6;
  background: transparent;
  color: #E3E5E6;
}
.app-container.dark .pagination-row .navigate-button:hover {
  border: 1px solid #778186;
  color: #778186;
}
.app-container.dark .pagination-row .go-to-page-button {
  background: #C6CACC;
  color: #C6CACC;
  font-weight: bold;
}
.app-container.dark .pagination-row .go-to-page-button:hover {
  background: #b9bdc0;
}
.app-container.dark .pagination-row .go-to-page-button.active {
  background: #C6CACC;
  color: #C6CACC;
  cursor: initial;
}
.app-container.dark .error-detail {
  border: 1px solid #E3E5E6;
  padding: 0.5rem;
}
.app-container.dark .checkbox-field-wrapper {
  display: flex;
  font-size: 0.75rem;
}
.app-container.dark .checkbox-field-wrapper .checkbox-wrapper {
  position: relative;
  height: 1rem;
  width: 1rem;
  margin: auto 0;
  cursor: pointer;
}
.app-container.dark .checkbox-field-wrapper .checkbox-wrapper .checkbox-background {
  top: 0.0625rem;
  border-radius: 0.1875rem;
  width: 0.875rem;
  height: 0.875rem;
  position: absolute;
}
.app-container.dark .checkbox-field-wrapper .checkbox-wrapper .checkbox {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1rem;
}
.app-container.dark .checkbox-field-wrapper .checkbox-wrapper .check {
  z-index: 1000;
}
.app-container.dark .checkbox-field-wrapper .checkbox-wrapper.active .checkbox-background {
  background-color: #367BF5;
}
.app-container.dark .checkbox-field-wrapper .checkbox-wrapper.active .checkbox {
  color: #FFF;
}
.app-container.dark .checkbox-field-wrapper .checkbox-wrapper.active .outline {
  color: #367BF5;
}
.app-container.dark .checkbox-field-wrapper .checkbox-wrapper.active .check {
  left: 0.05rem;
  width: 0.75rem;
}
.app-container.dark .checkbox-field-wrapper label {
  font-weight: 400;
  margin: auto 0 auto 0.25rem;
  user-select: none;
  cursor: pointer;
}
.app-container.dark .checkbox-field-wrapper.invalid {
  color: red;
}
.app-container.dark .checkbox-field-wrapper.disabled {
  color: #919699;
}
.app-container.dark .checkbox-field-wrapper.disabled label {
  cursor: default;
}
.app-container.dark .checkbox-field-wrapper.disabled .checkbox-wrapper {
  cursor: default;
}
.app-container.dark .checkbox-field-wrapper.disabled .checkbox-wrapper.active .checkbox-background {
  background-color: #919699;
}
.app-container.dark .checkbox-field-wrapper.disabled .checkbox-wrapper.active .outline {
  color: #919699;
}
.app-container.dark .text-field-wrapper {
  position: relative;
  width: 100%;
  height: 3.5rem;
  box-sizing: border-box;
}
.app-container.dark .text-field-wrapper label {
  background-color: #FFF;
  position: absolute;
  top: 0rem;
  left: 0.5rem;
  padding: 0 0.15rem;
  font-size: 0.75rem;
  line-height: 1rem;
  z-index: 1000;
  color: #5E6366;
  font-weight: 400;
  user-select: none;
}
.app-container.dark .text-field-wrapper.invalid label {
  color: red;
}
.app-container.dark .text-field-wrapper input {
  position: absolute;
  left: 0;
  top: 0.5rem;
  width: 100%;
  height: 2rem;
  background-color: transparent;
  padding: 0 0.5rem;
  border: 1px solid #E3E5E6;
  color: #5E6366;
  box-sizing: border-box;
  font-weight: 600;
  padding-top: 0.5rem;
}
.app-container.dark .text-field-wrapper input:hover,
.app-container.dark .text-field-wrapper input:active,
.app-container.dark .text-field-wrapper input:focus {
  outline: none;
}
.app-container.dark .text-field-wrapper input::placeholder {
  font-weight: 100;
  font-size: 80%;
  color: #C6CACC;
}
.app-container.dark .text-field-wrapper.invalid input {
  color: red;
  border-color: red;
}
.app-container.dark .text-field-wrapper.invalid input::placeholder {
  color: red;
}
.app-container.dark .text-field-wrapper .error-message {
  color: red;
  font-size: 0.75rem;
  position: absolute;
  left: 0rem;
  bottom: 0;
  line-height: 1rem;
  width: 100%;
  text-align: right;
  font-weight: 400;
}
.app-container.dark .text-field-wrapper.inline-label {
  display: flex;
  height: 2rem;
}
.app-container.dark .text-field-wrapper.inline-label label {
  position: initial;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 1rem;
  white-space: nowrap;
}
.app-container.dark .text-field-wrapper.inline-label input {
  position: initial;
  margin-top: auto;
  margin-bottom: auto;
  top: unset;
  padding-top: 0;
}
.app-container.dark .text-field-wrapper.inline-label .error-message {
  box-sizing: border-box;
  bottom: 0.45rem;
  padding-right: 0.5rem;
}
.app-container.dark .text-field-wrapper.inline-validation.inline-label {
  height: auto;
}
.app-container.dark .text-field-wrapper.text-small input {
  font-size: 0.75rem;
  letter-spacing: 0.25px;
}
.app-container.dark .text-field-wrapper.on-blackout input {
  color: #FFF;
}
.app-container.dark .text-field-wrapper.disabled input {
  background-color: #F1F3F4;
  color: #919699;
}
.app-container.dark .text-field-wrapper.disabled label {
  background-color: transparent;
  color: #919699;
}
.app-container.dark .address-field-wrapper {
  position: relative;
  height: 6.7rem;
  width: 100%;
  box-sizing: border-box;
}
.app-container.dark .address-field-wrapper .same-as-checkbox {
  position: absolute;
  top: -0.5rem;
  right: 0.5rem;
  padding: 0 0.25rem;
  display: flex;
  background: #fff;
  font-weight: 100;
}
.app-container.dark .address-field-wrapper .same-as-checkbox label {
  line-height: 1rem;
}
.app-container.dark .address-field-wrapper .address-field-border-wrapper {
  position: absolute;
  width: 100%;
  top: 0.5rem;
  left: 0rem;
  border: 1px solid #E3E5E6;
  box-sizing: border-box;
  padding-top: 0.5rem;
}
.app-container.dark .address-field-wrapper .address-field-border-wrapper > label {
  background-color: #FFF;
  position: absolute;
  top: -0.5rem;
  left: 0.5rem;
  padding: 0 0.15rem;
  font-size: 0.75rem;
  line-height: 1rem;
  z-index: 1000;
  color: #5E6366;
  font-weight: 400;
  user-select: none;
}
.app-container.dark .address-field-wrapper .address-field-border-wrapper.invalid label {
  color: red;
}
.app-container.dark .address-field-wrapper .address-field-border-wrapper.invalid {
  border-color: red;
}
.app-container.dark .address-field-wrapper .address-field-border-wrapper .text-field-wrapper.invalid > input {
  border-color: red;
  color: red;
}
.app-container.dark .address-field-wrapper .address-field-border-wrapper .text-field-wrapper.invalid > input::placeholder {
  color: red;
}
.app-container.dark .address-field-wrapper .address-field-border-wrapper .text-field-wrapper {
  height: 1.5rem;
}
.app-container.dark .address-field-wrapper .address-field-border-wrapper input {
  box-sizing: border-box;
  margin: 0;
  height: 1.5rem;
  border: none;
  background-color: transparent;
  padding: 0 0.5rem;
  color: #5E6366;
  font-weight: 600;
}
.app-container.dark .address-field-wrapper .address-field-border-wrapper input:hover,
.app-container.dark .address-field-wrapper .address-field-border-wrapper input:active,
.app-container.dark .address-field-wrapper .address-field-border-wrapper input:focus {
  outline: none;
}
.app-container.dark .address-field-wrapper .address-field-border-wrapper input::placeholder {
  font-weight: 100;
  font-size: 80%;
  color: #C6CACC;
}
.app-container.dark .address-field-wrapper .address-field-border-wrapper > input {
  width: 100%;
}
.app-container.dark .address-field-wrapper .address-field-border-wrapper .city-field {
  flex: 1;
}
.app-container.dark .address-field-wrapper .address-field-border-wrapper .state-field {
  width: 2.5rem;
  height: 1.5rem;
}
.app-container.dark .address-field-wrapper .address-field-border-wrapper .state-field input {
  padding: 0;
  text-align: center;
}
.app-container.dark .address-field-wrapper .address-field-border-wrapper .state-field .dropdown-content {
  top: 1.5rem;
  left: unset;
  right: -0.75rem;
}
.app-container.dark .address-field-wrapper .address-field-border-wrapper .zip-field {
  width: 6rem;
}
.app-container.dark .address-field-wrapper .address-field-border-wrapper .zip-field input {
  text-align: center;
}
.app-container.dark .address-field-wrapper .error-message {
  color: red;
  font-size: 0.75rem;
  position: absolute;
  left: 0;
  bottom: 0;
  line-height: 1rem;
  width: 100%;
  text-align: right;
  font-weight: 400;
}
.app-container.dark .address-field-wrapper.disabled .same-as-checkbox {
  background-color: transparent;
}
.app-container.dark .address-field-wrapper.disabled .address-field-border-wrapper {
  background-color: #F1F3F4;
  color: #919699;
}
.app-container.dark .address-field-wrapper.disabled .address-field-border-wrapper label {
  background-color: transparent;
  color: #919699;
}
.app-container.dark .dropdown-wrapper {
  position: relative;
  width: 100%;
  height: 3.5rem;
  box-sizing: border-box;
}
.app-container.dark .dropdown-wrapper label {
  background-color: #FFF;
  position: absolute;
  top: 0rem;
  left: 0.5rem;
  padding: 0 0.15rem;
  font-size: 0.75rem;
  line-height: 1rem;
  z-index: 1000;
  color: #5E6366;
  font-weight: 400;
  user-select: none;
}
.app-container.dark .dropdown-wrapper.invalid label {
  color: red;
}
.app-container.dark .dropdown-wrapper input {
  position: absolute;
  left: 0;
  top: 0.5rem;
  width: 100%;
  height: 2rem;
  background-color: transparent;
  padding: 0 0.5rem;
  border: 1px solid #E3E5E6;
  color: #5E6366;
  box-sizing: border-box;
  font-weight: 600;
  padding-top: 0.5rem;
  cursor: pointer;
  caret-color: transparent;
}
.app-container.dark .dropdown-wrapper input:hover,
.app-container.dark .dropdown-wrapper input:active,
.app-container.dark .dropdown-wrapper input:focus {
  outline: none;
}
.app-container.dark .dropdown-wrapper input::placeholder {
  font-weight: 100;
  font-size: 80%;
  color: #C6CACC;
}
.app-container.dark .dropdown-wrapper.invalid input {
  color: red;
  border-color: red;
}
.app-container.dark .dropdown-wrapper.invalid input::placeholder {
  color: red;
}
.app-container.dark .dropdown-wrapper .error-message {
  color: red;
  font-size: 0.75rem;
  position: absolute;
  left: 0rem;
  bottom: 0;
  line-height: 1rem;
  width: 100%;
  text-align: right;
  font-weight: 400;
}
.app-container.dark .dropdown-wrapper .dropdown-caret {
  position: absolute;
  color: #5E6366;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
}
.app-container.dark .dropdown-wrapper .dropdown-content {
  display: none;
  position: absolute;
  background-color: #FFF;
  z-index: 1100;
  top: 2.5rem;
  border: 1px solid #E3E5E6;
  border-top: none;
  box-sizing: border-box;
  max-height: 20rem;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 100%;
  width: auto;
}
.app-container.dark .dropdown-wrapper .dropdown-content .option {
  padding: 0.25rem 0.5rem;
  width: 100%;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1rem;
  box-sizing: border-box;
  cursor: pointer;
}
.app-container.dark .dropdown-wrapper .dropdown-content .option:hover {
  background-color: #F7F9FA;
}
.app-container.dark .dropdown-wrapper .dropdown-content .option.active {
  background-color: #F7F9FA;
}
.app-container.dark .dropdown-wrapper.active input {
  border-bottom: none;
}
.app-container.dark .dropdown-wrapper.active .dropdown-content {
  display: block;
}
.app-container.dark .dropdown-wrapper.invalid .dropdown-content {
  border-color: red;
}
.app-container.dark .dropdown-wrapper.field-small {
  height: 2.5rem;
  font-size: 0.75rem;
  letter-spacing: 0.25px;
}
.app-container.dark .dropdown-wrapper.field-small input {
  padding-top: 0;
  height: 2.5rem;
}
.app-container.dark .dropdown-wrapper.field-small svg {
  right: 0.75rem;
}
.app-container.dark .dropdown-wrapper.field-small .dropdown-content {
  top: 2rem;
}
.app-container.dark .dropdown-wrapper.field-x-small {
  height: 1.75rem;
  font-size: 0.75rem;
  letter-spacing: 0.25px;
}
.app-container.dark .dropdown-wrapper.field-x-small input {
  padding-top: 0;
  height: 1.75rem;
}
.app-container.dark .dropdown-wrapper.field-x-small svg {
  right: 0.5rem;
}
.app-container.dark .dropdown-wrapper.field-x-small .dropdown-content {
  top: 1.75rem;
}
.app-container.dark .dropdown-wrapper.no-label input {
  top: 0;
}
.app-container.dark .dropdown-wrapper.no-label .dropdown-caret {
  top: 0.5rem;
}
.app-container.dark .dropdown-wrapper.inline-label {
  display: flex;
  height: 2rem;
}
.app-container.dark .dropdown-wrapper.inline-label label {
  position: initial;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 1rem;
  white-space: nowrap;
}
.app-container.dark .dropdown-wrapper.inline-label input {
  position: initial;
  margin-top: auto;
  margin-bottom: auto;
  top: unset;
  padding-top: 0;
}
.app-container.dark .dropdown-wrapper.inline-label .dropdown-caret {
  top: 0.5rem;
}
.app-container.dark .dropdown-wrapper.inline-label .error-message {
  box-sizing: border-box;
  bottom: 0.45rem;
  padding-right: 0.5rem;
}
.app-container.dark .dropdown-wrapper.inline-label .dropdown-content {
  top: 2rem;
}
.app-container.dark .dropdown-wrapper.text-small input {
  font-size: 0.75rem;
  letter-spacing: 0.25px;
}
.app-container.dark .dropdown-wrapper.drop-up .dropdown-content {
  bottom: 1.75rem;
  top: unset;
  border-bottom: none;
  border-top: 1px solid #E3E5E6;
}
.app-container.dark .dropdown-wrapper.drop-up.active input {
  border-bottom: 1px solid #E3E5E6;
  border-top: none;
}
.app-container.dark .dropdown-wrapper.disabled input {
  background-color: #F1F3F4;
  color: #919699;
  cursor: default;
}
.app-container.dark .dropdown-wrapper.disabled label {
  background-color: transparent;
  color: #919699;
}
.app-container.dark .dropdown-wrapper.disabled .dropdown-caret {
  display: none;
}
.app-container.dark .radio-field-wrapper {
  user-select: none;
  display: flex;
}
.app-container.dark .radio-field-wrapper .radio-field-label {
  margin-top: auto;
  margin-bottom: auto;
}
.app-container.dark .radio-field-wrapper .option-label {
  margin-top: auto;
  margin-bottom: auto;
}
.app-container.dark .radio-field-wrapper .option-label.active span {
  font-weight: bolder;
}
.app-container.dark .radio-field-wrapper .option-label span {
  font-weight: 200;
  margin-left: 0.25rem;
}
.app-container.dark .radio-field-wrapper input {
  margin-left: 1rem;
}
.app-container.dark .radio-field-wrapper.disabled .radio-field-label {
  color: #919699;
}
.app-container.dark .radio-field-wrapper.disabled .option-label {
  color: #919699;
}
.app-container.dark .tab-group .tab-heading {
  border-bottom: 1px solid #E3E5E6;
  user-select: none;
}
.app-container.dark .tab-group .tab-heading .tab-button-wrapper {
  display: flex;
  min-height: 2rem;
}
.app-container.dark .tab-group .tab-heading .tab-button-wrapper .tab-button {
  width: 8rem;
}
.app-container.dark .tab-group .tab-heading .tab-button-indicator-wrapper {
  position: relative;
}
.app-container.dark .tab-group .tab-heading .tab-button-indicator-wrapper .tab-button-indicator {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 0.125rem;
  width: 8rem;
  background-color: #367BF5;
  -webkit-transition: left 0.25s ease-out;
  -moz-transition: left 0.25s ease-out;
  -o-transition: left 0.25s ease-out;
  transition: left 0.25s ease-out;
}
.app-container.dark .tab-group .tab-heading .tab-button-indicator-wrapper .tab-button-indicator.active-0 {
  left: 0;
}
.app-container.dark .tab-group .tab-heading .tab-button-indicator-wrapper .tab-button-indicator.active-1 {
  left: 8rem;
}
.app-container.dark .tab-group .tab-heading .tab-button-indicator-wrapper .tab-button-indicator.active-2 {
  left: 16rem;
}
.app-container.dark .tab-group .tab-heading .tab-button-indicator-wrapper .tab-button-indicator.active-3 {
  left: 24rem;
}
.app-container.dark .tab-group .tab-heading .tab-button-indicator-wrapper .tab-button-indicator.active-4 {
  left: 32rem;
}
.app-container.dark .tab-group .tab-heading .tab-button-indicator-wrapper .tab-button-indicator.active-5 {
  left: 40rem;
}
.app-container.dark .tab-group .tab-heading .tab-button-indicator-wrapper .tab-button-indicator.active-6 {
  left: 48rem;
}
.app-container.dark .tab-group .tab-heading .tab-button-indicator-wrapper .tab-button-indicator.active-7 {
  left: 56rem;
}
.app-container.dark .tab-group .tab-heading .tab-button-indicator-wrapper .tab-button-indicator.active-8 {
  left: 64rem;
}
.app-container.dark .tab-group .tab-heading .tab-button-indicator-wrapper .tab-button-indicator.active-9 {
  left: 72rem;
}
.app-container.dark .tab-group .tab-heading .tab-button-indicator-wrapper .tab-button-indicator.active-10 {
  left: 80rem;
}
.app-container.dark .tab-group .tab-heading .tab-button-indicator-wrapper .tab-button-indicator.active-11 {
  left: 88rem;
}
.app-container.dark .tab-group .tab-content {
  box-sizing: border-box;
}
.app-container.dark .tab-group .tab-content .tab {
  width: 100%;
  box-sizing: border-box;
  opacity: 0;
  display: none;
}
.app-container.dark .tab-group .tab-content .tab.active {
  display: block;
  opacity: 1;
  -webkit-animation: fadeIn 0.25s;
  animation: fadeIn 0.25s;
}
.app-container.dark .chip {
  display: flex;
  position: relative;
  font-size: 0.875rem;
  color: #5E6366;
  border-radius: 50rem;
  padding: 0.375rem 0.875rem;
  width: fit-content;
  height: 2.25rem;
  font-weight: 400;
  letter-spacing: 0.25px;
  line-height: 1.25rem;
  background-color: #F1F3F4;
  box-sizing: border-box;
  user-select: none;
}
.app-container.dark .chip.outline {
  background-color: transparent;
  border: 1px solid #E3E5E6;
}
.app-container.dark .chip.clickable {
  cursor: pointer;
}
.app-container.dark .chip.elevate {
  background-color: transparent;
  box-shadow: 0px 2px 6px rgba(8, 35, 48, 0.16), 0px 1px 2px rgba(8, 35, 48, 0.24);
}
.app-container.dark .chip.elevate.clickable {
  -webkit-transition: box-shadow 0.25s ease-out;
  -moz-transition: box-shadow 0.25s ease-out;
  -o-transition: box-shadow 0.25s ease-out;
  transition: box-shadow 0.25s ease-out;
}
.app-container.dark .chip.elevate.clickable:hover {
  box-shadow: 0px 4px 8px rgba(8, 35, 48, 0.16), 0px 2px 4px rgba(8, 35, 48, 0.24);
}
.app-container.dark .chip.elevate.clickable:active {
  box-shadow: none;
}
.app-container.dark .chip.primary {
  color: #367BF5;
}
.app-container.dark .chip.primary.outline {
  border-color: #367BF5;
}
.app-container.dark .chip.danger {
  color: #EA3D2F;
}
.app-container.dark .chip.danger.outline {
  border-color: #EA3D2F;
}
.app-container.dark .chip.accent {
  color: #069697;
}
.app-container.dark .chip.accent.outline {
  border-color: #069697;
}
.app-container.dark .chip.success {
  color: #2FA84F;
}
.app-container.dark .chip.success.outline {
  border-color: #2FA84F;
}
.app-container.dark .chip.chip-small {
  font-size: 0.7rem;
  padding: 0.125rem 0.5rem;
  line-height: 0.75rem;
  height: 1.5rem;
  letter-spacing: 1px;
  font-weight: 500;
}
.app-container.dark .chip.chip-small .icon-wrapper {
  font-size: 0.65rem;
  margin-top: 0.35rem;
}
.app-container.dark .chip.chip-small .icon-wrapper:first-of-type {
  margin-left: 0.25rem;
  margin-right: 0.125rem;
}
.app-container.dark .chip.chip-small .icon-wrapper:last-of-type {
  margin-left: 0.125rem;
  margin-right: 0.25rem;
}
.app-container.dark .chip.chip-small span {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.app-container.dark .chip.chip-small.outline .icon-wrapper {
  margin-top: 0.3rem;
}
.app-container.dark .chip .icon-wrapper {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 0.75rem;
}
.app-container.dark .chip span {
  padding-top: 2px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.app-container.dark .toggle-button {
  display: flex;
  width: fit-content;
  border: 1px solid #E3E5E6;
  border-radius: 0.25rem;
  font-weight: 400;
  user-select: none;
}
.app-container.dark .toggle-button .toggle-button-option {
  flex: 1;
  text-align: center;
  padding: 0.25rem 1rem;
  cursor: pointer;
  box-sizing: border-box;
  border-collapse: collapse;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.app-container.dark .toggle-button .toggle-button-option:hover {
  background-color: #F1F3F4;
  border-color: #F1F3F4;
}
.app-container.dark .toggle-button .toggle-button-option.active {
  background-color: #919699;
  border-color: #919699;
  color: white;
}
.app-container.dark .toggle-button .toggle-button-option + .toggle-button-option {
  border-left: 1px solid #E3E5E6;
}
.app-container.dark .toggle-button .toggle-button-option:last-of-type {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.app-container.dark .toggle-button .toggle-button-option:first-of-type {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.app-container.dark .toggle-button.primary .toggle-button-option.active:hover {
  color: #094abc;
  background-color: #f8fbff;
}
.app-container.dark .toggle-button.primary .toggle-button-option.active.active {
  background-color: #367BF5;
  border-color: #367BF5;
  color: white;
}
.app-container.dark .toggle-button.danger .toggle-button-option.active:hover {
  color: #a31b10;
  background-color: #fdeae8;
}
.app-container.dark .toggle-button.danger .toggle-button-option.active.active {
  background-color: #EA3D2F;
  border-color: #EA3D2F;
  color: white;
}
.app-container.dark .toggle-button.warn .toggle-button-option.active:hover {
  color: #9d6b08;
  background-color: #fdf1da;
}
.app-container.dark .toggle-button.warn .toggle-button-option.active.active {
  background-color: #F3AA18;
  border-color: #F3AA18;
  color: white;
}
.app-container.dark .toggle-button.success .toggle-button-option.active:hover {
  color: #19582a;
  background-color: #b8ebc5;
}
.app-container.dark .toggle-button.success .toggle-button-option.active.active {
  background-color: #2FA84F;
  border-color: #2FA84F;
  color: white;
}
.app-container.dark .toggle-button.accent .toggle-button-option.active:hover {
  color: #023535;
  background-color: #70f8f9;
}
.app-container.dark .toggle-button.accent .toggle-button-option.active.active {
  background-color: #069697;
  border-color: #069697;
  color: white;
}
.app-container.dark .toggle-button.toggle-button-small .toggle-button-option {
  padding: 0.125rem 0.5rem;
  font-size: 0.75rem;
}
.app-container.dark .toggle-button.toggle-button-x-small .toggle-button-option {
  padding: 0rem 0.25rem;
  font-size: 0.65rem;
}
.app-container.dark .toggle-button.elevate {
  box-shadow: 0px 2px 6px rgba(8, 35, 48, 0.16), 0px 1px 2px rgba(8, 35, 48, 0.24);
}
.app-container.dark .query-builder-condition-set {
  width: fit-content;
  box-sizing: border-box;
  position: relative;
}
.app-container.dark .query-builder-condition-set .query-builder-condition-set-pointer {
  border-top: 1px dashed #C6CACC;
  position: absolute;
  top: 1rem;
  left: -1rem;
  width: 1rem;
}
.app-container.dark .query-builder-condition-set .query-builder-condition-container {
  border-left: 1px dashed #C6CACC;
  margin-left: 1rem;
  box-sizing: border-box;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 1rem;
}
.app-container.dark .query-builder-condition-set .query-builder-condition-container .query-builder-condition {
  position: relative;
  display: flex;
  height: 3rem;
  margin-top: 1rem;
  margin-left: 1rem;
  box-sizing: border-box;
  box-shadow: 0px 2px 6px rgba(8, 35, 48, 0.16), 0px 1px 2px rgba(8, 35, 48, 0.24);
  width: calc(100% - 1rem);
}
.app-container.dark .query-builder-condition-set .query-builder-condition-container .query-builder-condition > span {
  margin-top: auto;
  margin-bottom: auto;
}
.app-container.dark .query-builder-condition-set .query-builder-condition-container .query-builder-condition:first-of-type {
  margin-top: 0;
}
.app-container.dark .query-builder-condition-set .query-builder-condition-container .query-builder-condition .query-builder-condition-pointer {
  border-top: 1px dashed #C6CACC;
  position: absolute;
  top: 1.5rem;
  left: -1rem;
  width: 1rem;
}
.app-container.dark .query-builder-condition-set .query-builder-condition-set {
  margin-top: 1rem;
  margin-left: 1rem;
}
.app-container.dark .query-builder-condition-set .add-condition-button {
  margin-left: 0.25rem;
  margin-top: 0.25rem;
}
.app-container.dark .menu {
  opacity: 0;
  background-color: #FFF;
  z-index: 1001;
  height: 0;
  overflow: hidden;
  box-shadow: 0px 4px 8px rgba(8, 35, 48, 0.16), 0px 2px 4px rgba(8, 35, 48, 0.24);
  user-select: none;
  -webkit-transition: opacity 0.1s ease-out;
  -moz-transition: opacity 0.1s ease-out;
  -o-transition: opacity 0.1s ease-out;
  transition: opacity 0.1s ease-out;
}
.app-container.dark .menu.active {
  opacity: 1;
  height: auto;
}
.app-container.dark .menu button.menu-button {
  padding: 0.25rem 0.5rem;
  width: 100%;
}
.app-container.dark .menu button.menu-button *:last-child {
  margin-right: auto;
}
.app-container.dark .menu .menu-item {
  width: 100%;
}
.app-container.dark .menu .menu-item:first-of-type button.menu-button {
  padding-top: 0.5rem;
}
.app-container.dark .menu .menu-item:last-of-type button.menu-button {
  padding-bottom: 0.5rem;
}
.app-container.dark .menu .divider {
  border-top: 1px solid #E3E5E6;
  margin: 0.25rem 0;
}
.app-container.dark .menu .menu-item-dragging {
  position: absolute;
  border: 1px solid #E3E5E6;
  background-color: #FFF;
}
.app-container.dark .menu-item-drop-placeholder {
  width: 100%;
  margin-top: 2px;
  box-sizing: border-box;
  background-color: #edf2f7;
  border: 2px dashed #cbd5e0;
}
.app-container.dark .modal-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
  display: flex;
}
.app-container.dark .modal-wrapper .modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2001;
}
.app-container.dark .modal-wrapper.backdrop .modal-backdrop {
  background-color: rgba(0, 0, 0, 0.87);
  opacity: 0.5;
}
.app-container.dark .modal-wrapper .modal-window {
  background-color: #FFF;
  border-radius: 0.5rem;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  z-index: 2002;
  margin-top: 10rem;
  max-height: calc(100% - 20rem);
  box-shadow: 0px 16px 24px rgba(8, 35, 48, 0.08), 0px 6px 12px rgba(8, 35, 48, 0.14);
  display: flex;
  flex-direction: column;
}
.app-container.dark .modal-wrapper .modal-window .header {
  background-color: #F1F3F4;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  padding: 0.5rem 1rem;
  display: flex;
}
.app-container.dark .modal-wrapper .modal-window .header .close-button {
  margin-left: auto;
  padding: 0;
  height: auto;
  width: auto;
  min-width: unset;
}
.app-container.dark .modal-wrapper .modal-window .content {
  padding: 1rem;
  flex: 1;
  overflow: auto;
}
.app-container.dark .modal-wrapper .modal-window .footer {
  border-top: 1px solid #E3E5E6;
  padding: 0.5rem 1rem;
}
.app-container.dark .modal-wrapper.blackout .modal-backdrop {
  background-color: rgba(0, 0, 0, 0.87);
  opacity: 1;
}
.app-container.dark .modal-wrapper.blackout .modal-window {
  background: transparent;
  color: #F7F9FA;
  border-color: transparent;
  box-shadow: none;
  background-color: transparent;
  border: none;
  outline: none;
}
.app-container.dark .modal-wrapper.blackout .modal-window .header {
  background: transparent;
}
.app-container.dark .modal-wrapper.blackout .modal-window .header .close-button {
  color: #F7F9FA;
}
.app-container.dark .modal-wrapper.blackout .modal-window .footer {
  border-top: none;
}
.app-container.dark .modal-wrapper.no-header .modal-window .header {
  display: none;
}
.app-container.dark .loading-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #FFF;
  z-index: 3000;
  opacity: 0.4;
}
.app-container.dark .toast-container {
  position: fixed;
  top: 2rem;
  right: 2rem;
  width: 100%;
  max-width: 25rem;
  z-index: 5000;
}
.app-container.dark .toast-container .toast-wrapper + .toast-wrapper {
  margin-top: 0.5rem;
}
.app-container.dark .toast-container .toast-wrapper {
  display: flex;
  padding: 0 0 0 0.5rem;
  color: #3E4345;
  border-radius: 0.5rem;
}
.app-container.dark .toast-container .toast-wrapper.success {
  border: 1px solid #2FA84F;
  background-color: rgba(47, 168, 79, 0.8);
}
.app-container.dark .toast-container .toast-wrapper.danger {
  border: 1px solid #EA3D2F;
  background-color: rgba(234, 61, 47, 0.8);
}
.app-container.dark .toast-container .toast-wrapper.accent {
  border: 1px solid #069697;
  background-color: rgba(6, 150, 151, 0.8);
}
.app-container.dark .toast-container .toast-wrapper.warn {
  border: 1px solid #F3AA18;
  background-color: rgba(243, 170, 24, 0.8);
}
.app-container.dark .toast-container .toast-wrapper.primary {
  border: 1px solid #367BF5;
  background-color: rgba(54, 123, 245, 0.8);
}
.app-container.dark .toast-container .toast-message {
  line-height: 1rem;
}
.app-container.dark .toast-container .toast-body {
  background-color: #FFF;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  padding: 0.5rem 1rem;
}
.app-container.dark .toast-container .close-button {
  height: auto;
  width: auto;
  min-width: unset;
  color: #C6CACC;
  background-color: transparent;
  padding: 0;
}
.app-container.dark .toast-container .close-button:hover {
  color: #3E4345;
}
.app-container.dark .toast-container .toast-icon {
  color: #F7F9FA;
}
.app-container.dark .react-grid-layout {
  position: relative;
  transition: height 200ms ease;
  margin-top: 6px;
  margin-left: -10px;
}
.app-container.dark .react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
  box-shadow: 0px 2px 6px rgba(8, 35, 48, 0.16), 0px 1px 2px rgba(8, 35, 48, 0.24);
  box-sizing: border-box;
}
.app-container.dark .react-grid-item img {
  pointer-events: none;
  user-select: none;
}
.app-container.dark .react-grid-item.cssTransforms {
  transition-property: transform;
}
.app-container.dark .react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}
.app-container.dark .react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}
.app-container.dark .react-grid-item.dropping {
  visibility: hidden;
}
.app-container.dark .react-grid-item.react-grid-placeholder {
  background-color: #edf2f7;
  border: 2px dashed #cbd5e0;
  opacity: 0.5;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.app-container.dark .react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
}
.app-container.dark .react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 3px;
  width: 5px;
  height: 5px;
  border-right: 2px solid rgba(0, 0, 0, 0.4);
  border-bottom: 2px solid rgba(0, 0, 0, 0.4);
}
.app-container.dark .react-resizable-hide > .react-resizable-handle {
  display: none;
}
.app-container.dark .react-grid-item > .react-resizable-handle.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.app-container.dark .react-grid-item > .react-resizable-handle.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.app-container.dark .react-grid-item > .react-resizable-handle.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.app-container.dark .react-grid-item > .react-resizable-handle.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.app-container.dark .react-grid-item > .react-resizable-handle.react-resizable-handle-w,
.app-container.dark .react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.app-container.dark .react-grid-item > .react-resizable-handle.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.app-container.dark .react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
.app-container.dark .react-grid-item > .react-resizable-handle.react-resizable-handle-n,
.app-container.dark .react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.app-container.dark .react-grid-item > .react-resizable-handle.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.app-container.dark .react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}
.app-container.dark .react-resizable {
  position: relative;
}
.app-container.dark .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
  background-position: bottom right;
  padding: 0 3px 3px 0;
}
.app-container.dark .react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.app-container.dark .react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.app-container.dark .react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.app-container.dark .react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.app-container.dark .react-resizable-handle-w,
.app-container.dark .react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.app-container.dark .react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.app-container.dark .react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
.app-container.dark .react-resizable-handle-n,
.app-container.dark .react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.app-container.dark .react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.app-container.dark .react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}
