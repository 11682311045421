.buttons() {

    // create local variables so that they can be passed as params into mixins
    @button-primary: .themes[@@primary];
    @button-danger: .themes[@@danger];
    @button-warn: .themes[@@warn];
    @button-success: .themes[@@success];
    @button-accent: .themes[@@accent];
    
    button {
        min-width: 7.5rem;
        display: flex;
        position: relative;
        cursor: pointer;
        user-select: none;

        .transitions();
        .buttonText();
        .flatten();
        .buttonShape();

        &.button-x-small {
            height: 1.5rem;
            font-size: .6rem;
            min-width: 0rem;
            
            svg {
                font-size: .75rem;
            }
        }

        &.button-small {
            height: 2rem;
            font-size: .7rem;
            min-width: 4.5rem;
        }

        &.button-large {
            height: 3.5rem;
            font-size: 1.25rem;
            letter-spacing: 0;
        }

        &.button-inline {
            min-width: 0;
            height: auto;

            span {
                margin: 0;
            }
        }        
            
        &.disabled {
            .disabled();
        }
        
        &.primary {
            .primary-bg();

            &:hover:enabled {
                background-color: darken(@button-primary, 10%);
            }
            &:active:enabled {
                background-color: lighten(@button-primary, 15%);
            }

            .elevate-2(rgba(54, 123, 245, 0.16), rgba(54, 123, 245, 0.24));

            &.link {
                .buttonLink(@button-primary);
            }

            &.smooth {
                .buttonSmooth(@button-primary);
            }

            &.outline {
                .buttonOutline(@button-primary);
            }
            
            &.invert {
                .buttonInvert(@button-primary);
            }

            &.button-search{
                .button-search(@button-primary);
            }
            
            &.disabled {
                .disabled();
            }
        }

        &.danger {
            .danger-bg();

            &:hover:enabled {
                background-color: darken(@button-danger, 10%);
            }
            &:active:enabled {
                background-color: lighten(@button-danger, 15%);
            }

            &.link {
                .buttonLink(@button-danger);
            }

            &.smooth {
                .buttonSmooth(@button-danger);
            }
            
            &.outline {
                .buttonOutline(@button-danger);
            }
            
            &.invert {
                .buttonInvert(@button-danger);
            }

            &.button-search{
                .button-search(@button-danger);
            }
            
            &.disabled {
                .disabled();
            }
        }

        &.warn {
            .warn-bg();

            &:hover:enabled {
                background-color: darken(@button-warn, 10%);
            }
            &:active:enabled {
                background-color: lighten(@button-warn, 15%);
            }

            &.link {
                .buttonLink(@button-warn);
            }

            &.smooth {
                .buttonSmooth(@button-warn);
            }

            &.outline {
                .buttonOutline(@button-warn);
            }
            
            &.invert {
                .buttonInvert(@button-warn);
            }

            &.button-search{
                .button-search(@button-warn);
            }
            
            &.disabled {
                .disabled();
            }
        }

        &.accent {
            .accent-bg();

            &:hover:enabled {
                background-color: darken(@button-accent, 10%);
            }
            &:active:enabled {
                background-color: lighten(@button-accent, 15%);
            }

            &.link {
                .buttonLink(@button-accent);
            }

            &.smooth {
                .buttonSmooth(@button-accent);
            }

            &.outline {
                .buttonOutline(@button-accent);
            }
            
            &.invert {
                .buttonInvert(@button-accent);
            }

            &.button-search{
                .button-search(@button-accent);
            }
            
            &.disabled {
                .disabled();
            }
        }

        &.success {
            .success-bg();

            &:hover:enabled {
                background-color: darken(@button-success, 10%);
            }
            &:active:enabled {
                background-color: lighten(@button-success, 15%);
            }

            &.link {
                .buttonLink(@button-success);
            }

            &.smooth {
                .buttonSmooth(@button-success);
            }
            
            &.outline {
                .buttonOutline(@button-success);
            }
            
            &.invert {
                .buttonInvert(@button-success);
            }

            &.button-search{
                .button-search(@button-success);
            }
            
            &.disabled {
                .disabled();
            }
        }

        &.oval {
            .oval();
        }

        &.circle {
            .oval();
            min-width: 0;
            width: 2.5rem;

            &.button-small {
                width: 2rem;
            }
            
            &.button-x-small {
                width: 1.5rem;
            }
        }
        &.circle.button-large {
            width: 3.5rem;
        }
        &.circle.button-large .icon-wrapper {
            margin: auto;
            font-size: 1.5rem;
        }
            
        &.elevate {
            .elevate-1();

            &:hover {
                .elevate-2();
            }

            &:active {
                .elevate-0();
            }
        }

        .badge {
            position: absolute;
            top: -.25rem;
            right: -1rem;
            border-radius: 10rem;
            padding: .25rem .2rem .15rem .2rem;
            background-color: .themes[@@gray7];
            color: .themes[@@gray0];
            font-size: .75rem;
            font-weight: 300;
            min-width: 1rem;

            &.primary {
                .primary-bg();
            }

            &.danger {
                .danger-bg();
            }

            &.warn {
                .warn-bg();
            }

            &.success {
                .success-bg();
            }
        }

        .icon-wrapper {
            margin-top: auto;
            margin-bottom: auto;
            font-size: 1.125rem;
        }

        span { 
            padding-top: 2px;   
            margin-top: auto;
            margin-bottom: auto;
            margin-left: .5rem;
            margin-right: .5rem;
        }

        &.square {
            flex-direction: column;
            height: 3rem;
            width: 3rem;
            justify-content: space-between;
            min-width: 0;

            .icon-wrapper { 
                font-size: 1.25rem;
                margin: auto;
                display: flex;
            }

            .badge {
                right: -1.5rem;
            }

            &.button-small {
                width: fit-content;
                height: 1.5rem;
                min-width: 1.5rem;
                font-size: .75rem;
                padding: 0;

                span {
                    margin: auto;
                }

                svg {
                    font-size: .75rem;
                }
            }

            &.button-x-small {
                width: fit-content;
                height: 1.25rem;
                min-width: 1.25rem;
                font-size: .75rem;
                padding: 0;
                
                span {
                    margin: auto;
                }
            }
        }

        &.tile {
            flex-direction: column;
            min-height: 4rem;
            min-width: 4rem;
            padding: 0;
            justify-content: space-evenly;
            height: auto;

            .icon-wrapper {
                margin: 0 auto;
                font-size: 1.25rem;
                padding: .75rem;
            }

            span:not(.badge) {
                font-size: .75rem;
                margin: 0 auto;
                padding: 0;
                text-transform: none;
            }

            .badge {
                right: -1.25rem;
            }

            &.button-small {
                font-size: 0.7rem;

                .icon-wrapper {
                    padding: .5rem;
                }
            }
        }

        &.highlight-icon {
            background-color: transparent;

            .icon-wrapper {
                padding: .75rem;
                border-radius: .2rem;
            }

            &:hover .icon-wrapper { 
                background-color: #fff;
            }
        }
    }

    .left-nav > button {
        color: #A6ADAF;

        .icon-wrapper {
            color: #FFF;
        }
        
        &:hover .icon-wrapper { 
            background-color: #5E6366;
        }
        
        &:not(.disabled).active {
            color: #FFF;

            .icon-wrapper { 
                background-color: #FFF;
                color: rgba(0, 0, 0, 0.87);
            }
        }
    }

    .buttonLink(@color) {
        background-color: transparent;
        color: @color;
        box-shadow: none;
        height: auto;
        text-transform: none;
        padding: 0;
        
        &:hover:enabled {
            color: darken(@color, 20%);
            background-color: transparent;
        }
        &:active:enabled {
            background-color: transparent;
        }

        &.disabled {
            .disabled();     
        }
    }
    
    .buttonInvert(@color) {
        background-color: .themes[@@gray0];
        color: @color;
        font-weight: 500;

        &:hover:enabled {
            color: darken(@color, 20%);
            background-color: lighten(@color, 40%);
        }
        &:active:enabled {
            background-color: lighten(@color, 60%);
        }

        &.disabled {
            .disabled();     
        }
    }

    .buttonSmooth(@color) {
        background-color: lighten(@color, 37.5%);
        color: @color;
        font-weight: 500;
        
        &:hover:enabled {
            color: darken(@color, 20%);
            background-color: lighten(@color, 20%);
        }
        &:active:enabled {
            background-color: lighten(@color, 60%);
        }

        &.disabled {
            .disabled();     
        }
    }

    .buttonOutline(@color) {
        .buttonInvert(@color);
        border-color: @color;        

        &:hover:enabled {
            border-color: darken(@color, 20%);
        }

        &.disabled {
            .disabled();     
        }
    }

    .transitions() {
        -webkit-transition: all 0.25s ease-out;
        -moz-transition: all 0.25s ease-out;
        -o-transition: all 0.25s ease-out;
        transition: all 0.25s ease-out;
    }

    .buttonText() {
        font-family: Roboto, sans-serif;
        line-height: .875rem;
        letter-spacing: 0.75px;
        text-transform: uppercase;
        font-size: .875rem;
        font-weight: 400;
    }

    .buttonShape() {
        padding: 0 0.703125rem;    
        border-radius: .25rem;
        border-width: 1px;
        border-style: solid;
        height: 2.5rem;
        width: fit-content;
        justify-content: space-evenly;
    }
    
    .oval() {
        border-radius: 6.1875rem;
    }

    .button-search(@color) {
        border-top: 1px solid .themes[@@gray3];
        border-right: 1px solid .themes[@@gray3];
        border-bottom: 1px solid .themes[@@gray3];
        border-left: none;
        background-color: transparent;
        box-shadow: none;
        border-radius: 0;
        color: @color;
        min-width: 0;
        
        &:hover:enabled {
            color: darken(@color, 20%);
            background-color: lighten(@color, 40%);
        }
        &:active:enabled {
            background-color: lighten(@color, 60%);
        }
    }

    .disabled() {
        cursor: default;
        color: .themes[@@gray4];

        &:hover:disabled {
            color: .themes[@@gray4];
        }

        &:active:disabled {
            color: .themes[@@gray4];
        }           
    }

    .drag-handle {
        cursor: move;
        user-select: none;
    }
}