.modals() {

    .modal-wrapper {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2000;
        display: flex;

        .modal-backdrop {
            position: fixed;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;    
            z-index: 2001;
        }

        &.backdrop .modal-backdrop {
            background-color: .themes[@@gray9];
            opacity: .5;
        }
        
        .modal-window {
            background-color: .themes[@@gray0];
            border-radius: .5rem;
            width: 50%;
            margin-left: auto;
            margin-right: auto;
            z-index: 2002;
            margin-top: 10rem;
            max-height: calc(100% - 20rem);
            .elevate-4();
            display: flex;
            flex-direction: column;

            .header {
                background-color: .themes[@@gray2];
                border-top-left-radius: .5rem;
                border-top-right-radius: .5rem;
                padding: .5rem 1rem;
                display: flex;

                .close-button {
                    margin-left: auto;
                    padding: 0;
                    height: auto;
                    width: auto;
                    min-width: unset;
                }
            }

            .content {
                padding: 1rem;
                flex: 1;
                overflow: auto;
            }

            .footer {
                border-top: 1px solid .themes[@@gray3];
                padding: .5rem 1rem;
            }
        }

        &.blackout {
            .modal-backdrop {
                background-color: .themes[@@gray9];
                opacity: 1;
            }

            .modal-window {
                background: transparent;
                color: .themes[@@gray1];
                .flatten();

                .header {
                    background: transparent;

                    .close-button {
                        color: .themes[@@gray1];
                    }
                }

                .footer {
                    border-top: none;
                }
            }
        }

        &.no-header .modal-window .header {
            display: none;
        }
    }
}