/*
    Storybook Preview Pane
*/

.storybook() {
    .sb-show-main #root {
        overflow: auto;
        margin: 0 !important;
        width: 100%;
    }
}