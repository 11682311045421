.dropdown() {

    .dropdown-wrapper {
        position: relative;
        width: 100%;
        height: 3.5rem;
        box-sizing: border-box;
     
        label {
            background-color: #FFF;
            position: absolute;
            top: 0rem;
            left: 0.5rem;
            padding: 0 0.15rem;
            font-size: 0.75rem;
            line-height: 1rem;
            z-index: 1000;
            color: .themes[@@gray7];
            font-weight: 400;
            user-select: none;
        }

        &.invalid label {
            color: red;
        }

        input {
            position: absolute;
            left: 0;
            top: 0.5rem;
            width: 100%;
            height: 2rem;
            background-color: transparent;
            padding: 0 0.5rem;
            border: 1px solid .themes[@@gray3];
            color: .themes[@@gray7];
            box-sizing: border-box;
            font-weight: 600;
            padding-top: .5rem;
            cursor: pointer;
            caret-color: transparent;

            &:hover, &:active, &:focus {
                outline: none;
            }

            &::placeholder {
                font-weight: 100;
                font-size: 80%;
                color: .themes[@@gray4];
            }
        }

        &.invalid {
          
            input {
                color: red;
                border-color: red;

                &::placeholder { 
                    color: red;
                }
            }
        }

        .error-message { 
            color: red;
            font-size: 0.75rem;
            position: absolute;
            left: 0rem;
            bottom: 0;
            line-height: 1rem;
            width: 100%;
            text-align: right;
            font-weight: 400;
        }

        .dropdown-caret {
            position: absolute;
            color: .themes[@@gray7];
            right: 1rem;
            top: 1rem;  
            cursor: pointer;  
        }

        .dropdown-content {
            display: none;
            position: absolute;
            background-color: #FFF;
            z-index: 1100;
            top: 2.5rem;
            border: 1px solid .themes[@@gray3];
            border-top: none;
            box-sizing: border-box;
            max-height: 20rem;
            overflow-y: auto;
            overflow-x: hidden;
            min-width: 100%;
            width: auto;

            .option {
                padding: .25rem .5rem;
                width: 100%;
                font-weight: normal;
                font-size: .75rem;
                line-height: 1rem;
                box-sizing: border-box;
                cursor: pointer;

                &:hover {
                    background-color: .themes[@@tableRowHoverColor];
                }

                &.active {
                    background-color: .themes[@@tableRowHoverColor];
                }
            }
        }

        &.active {

            input {
                border-bottom: none;
            }

            .dropdown-content {
                display: block;
            }
        }

        &.invalid .dropdown-content {
            border-color: red;
        }

        &.field-small {
            height: 2.5rem;
            .text-small();
            
            input {
                padding-top: 0;
                height: 2.5rem;
            }

            svg {
                right: .75rem;
            }

            .dropdown-content {
                top: 2rem;
            }
        }

        &.field-x-small {
            height: 1.75rem;
            .text-small();
            
            input {
                padding-top: 0;
                height: 1.75rem;
            }

            svg {
                right: .5rem;
            }

            .dropdown-content {
                top: 1.75rem;
            }
        }

        &.no-label {
            input {
                top: 0;
            }

            .dropdown-caret {
                top: .5rem;
            }
        }
        
        &.inline-label {
            display: flex;
            height: 2rem;

            label {
                position: initial;
                margin-top: auto;
                margin-bottom: auto;
                margin-right: 1rem;
                white-space: nowrap;
            }

            input {
                position: initial;
                margin-top: auto;
                margin-bottom: auto;
                top: unset;
                padding-top: 0; 
            }
            
            .dropdown-caret {
                top: .5rem;
            }

            .error-message {
                box-sizing: border-box;
                bottom: .45rem;
                padding-right: .5rem;
            }

            .dropdown-content {
                top: 2rem;
            }
        }

        &.text-small input {
            .text-small();
        }

        &.drop-up {

            .dropdown-content {
                bottom: 1.75rem;
                top: unset;
                border-bottom: none;
                border-top: 1px solid #E3E5E6;
            }

            &.active {

                input {
                    border-bottom: 1px solid .themes[@@gray3];
                    border-top: none;
                }
            }
        }

        &.disabled {
            input {
                background-color: .themes[@@gray2];
                color: .themes[@@gray6];
                cursor: default;
            }

            label {
                background-color: transparent;
                color: .themes[@@gray6];
            }

            .dropdown-caret {
                display: none;
            }
        }
    }
}