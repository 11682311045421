.toggleButtons() {
    // create local variables so that they can be passed as params into mixins
    @button-primary: .themes[@@primary];
    @button-danger: .themes[@@danger];
    @button-warn: .themes[@@warn];
    @button-success: .themes[@@success];
    @button-accent: .themes[@@accent];

    .toggle-button {
        display: flex;
        width: fit-content;
        border: 1px solid .themes[@@gray3];
        border-radius: .25rem;
        font-weight: 400;
        user-select: none;

        .toggle-button-option {
            flex: 1;
            text-align: center;
            padding: .25rem 1rem;
            cursor: pointer;            
            box-sizing: border-box;
            border-collapse: collapse;

            -webkit-transition: all 0.25s ease-in-out;
            -moz-transition: all 0.25s ease-in-out;
            -o-transition: all 0.25s ease-in-out;
            transition: all 0.25s ease-in-out;

            &:hover {
                background-color: .themes[@@gray2];
                border-color: .themes[@@gray2];
            }

            &.active {
                background-color: .themes[@@gray6];
                border-color: .themes[@@gray6];
                color: white;
            }
        }

        .toggle-button-option + .toggle-button-option {
            border-left: 1px solid .themes[@@gray3];
        }

        .toggle-button-option:last-of-type {
            border-top-right-radius: .25rem;
            border-bottom-right-radius: .25rem;
        }

        .toggle-button-option:first-of-type {
            border-top-left-radius: .25rem;
            border-bottom-left-radius: .25rem;
        }

        &.primary {
            .applyColor(@button-primary);
        }
        &.danger {
            .applyColor(@button-danger);
        }
        &.warn {
            .applyColor(@button-warn);
        }
        &.success {
            .applyColor(@button-success);
        }
        &.accent {
            .applyColor(@button-accent);
        }

        &.toggle-button-small {

            .toggle-button-option {
                padding: .125rem .5rem;
                font-size: .75rem;
            }
        }

        &.toggle-button-x-small {

            .toggle-button-option {
                padding: 0rem .25rem;
                font-size: .65rem;
            }
        }

        &.elevate {
            .elevate-1();
        }
    }

    .applyColor(@color) {

        .toggle-button-option.active {
        
            &:hover {
                color: darken(@color, 20%);
                background-color: lighten(@color, 40%);
            }

            &.active {
                background-color: @color;
                border-color: @color;
                color: white;
            }        
        }
    }
}