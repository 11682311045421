.selects() {

    select { 
        .flatten();
    }

    .flatten() { 
        background-color: transparent;
        border: none;
        outline: none;
    }
}