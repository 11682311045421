.addressField() {

    .address-field-wrapper {
        position: relative;
        height: 6.7rem;
        width: 100%;
        box-sizing: border-box;

        .same-as-checkbox {
            position: absolute;
            top: -.5rem;
            right: .5rem;
            padding: 0 .25rem;
            display: flex;
            background: #fff;
            font-weight: 100;

            label {
                line-height: 1rem;
            }
        }

        .address-field-border-wrapper {
            position: absolute;
            width: 100%;
            top: .5rem;
            left: 0rem;
            border: 1px solid .themes[@@gray3];
            box-sizing: border-box;
            padding-top: .5rem;

            & > label {
                background-color: #FFF;
                position: absolute;
                top: -.5rem;
                left: .5rem;
                padding: 0 0.15rem;
                font-size: .75rem;
                line-height: 1rem;
                z-index: 1000;
                color: .themes[@@gray7];
                font-weight: 400;
                user-select: none;
            }

            &.invalid label {
                color: red;
            }

            &.invalid {
                border-color: red;
            }

            .text-field-wrapper.invalid > input {
                border-color: red;
                color: red;

                &::placeholder {
                    color: red;
                }
            }

            .text-field-wrapper {
                height: 1.5rem;
            }

            input {
                box-sizing: border-box;
                margin: 0;
                height: 1.5rem;
                border: none;
                background-color: transparent;
                padding: 0 0.5rem;
                color: .themes[@@gray7];
                font-weight: 600;

                &:hover, &:active, &:focus {
                    outline: none;
                }

                &::placeholder {
                    font-weight: 100;
                    font-size: 80%;
                    color: .themes[@@gray4];
                }
            }

            & > input {
                width: 100%;
            }

            .city-field { 
                flex: 1;
            }

            .state-field {
                width: 2.5rem;
                height: 1.5rem;

                input {
                    padding: 0;
                    text-align: center;
                }

                .dropdown-content {
                    top: 1.5rem;    
                    left: unset;
                    right: -.75rem;
                }
            }

            .zip-field {
                width: 6rem;
                
                input {
                    text-align: center;
                }
            }
        }
        
        .error-message { 
            color: red;
            font-size: 0.75rem;
            position: absolute;
            left: 0;
            bottom: 0;
            line-height: 1rem;
            width: 100%;
            text-align: right;
            font-weight: 400;
        }

        &.disabled {
            .same-as-checkbox {
                background-color: transparent;
            }
            
            .address-field-border-wrapper {
                background-color: .themes[@@gray2];
                color: .themes[@@gray6];

                label {
                    background-color: transparent;
                    color: .themes[@@gray6];
                }
            }
        }
    }
}